import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import Header from '../../components/header/Header'
import { getUrlParameter } from '../../utils/helpers'
import GlobalOverlay from '../../components/global-overlay/GlobalOverlay'
import Modal from '../../components/modal/Modal'
import Datepicker from '../../components/datepicker/Datepicker'
import commonFunctionsService from '../../services/CommonFunctionsService'
import { apiCallsCommercial, apiUrlGetDocument } from '../../utils/apiCallsCommercial'
import { CONST } from '../../CONST'
import cloneDeep from 'lodash'
import jQuery from "jquery"
import analyticsDataLayer from '../../services/AnalyticsDataLayer'


class Quote extends Component {
    constructor() {
        super()

        // Bind this to element function calls for better performance / practise
        // - ref: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
        this.saveProject = this.saveProject.bind(this)
        this.sendEmailCustomerQuote = this.sendEmailCustomerQuote.bind(this)
        this.requestWholesalersQuote = this.requestWholesalersQuote.bind(this)
        this.hideConfirmationModal = this.hideConfirmationModal.bind(this)
        this.showEmailCustomerQuote = this.showEmailCustomerQuote.bind(this)
        // this.handleCustomerDetailChange = this.handleCustomerDetailChange.bind(this)

        // Init states
        this.state = {
            apiError: '',
            pageInfo: '',
            promoCode: '',
            purchaseNumber: '',
            attachedPO: '',
            userData: {},
            showBreakdown: false,
            showPromoCodeInput: false,
            validation: {
                message: '',
                isError: false,
                disclaimerCheckbox: false,
                promoCodeInput: false,
                // purchaseOrderNumber: false,
                // noAttachedPO: false
            },
            isLoading: {
                app: true,
                promoCodeApi: false,
                placeOrderApi: false,
                drawing: false,
                attachingPO: false,
                emailQuote: false,
                updateDetails: false
            },
            drawings: [],
            showConfirmationModal: false,
            projectID: getUrlParameter('projectID'),
            contractorRequestAgain: false,
            preferredWholesalerID: getUrlParameter('wholesalerBranchID'),
            wholesalerToSendQuote: getUrlParameter('wholesalerBranchID'),
            selectedWholesalerIDForProduction: '',
            showEmailCustomerQuoteModal: false,
            QuoteCustomerQuoteRef: '',
            QuoteCompanyName: '',
            QuoteCustomerName: '',
            QuoteCustomerAddress: '',
            QuoteCustomerPhone: '',
            QuoteCustomerEmail: '',
            QuoteCustomerMarkupPercent: 125,
            orderPlacementCondition1Accepted: false,
            orderPlacementCondition2Accepted: false,
            orderPlacementCondition3Accepted: false,
            orderPlacementCondition4Accepted: false,
            orderPlacementCondition5Accepted: false
        }

        // Temp user data
        this.userData = {}
    }

    componentDidMount() {
        let isConsultant = false
        let preferredWholesaler = this.state.preferredWholesalerID
        if (preferredWholesaler === '') {
            preferredWholesaler = -1 // default value for API
        }

        // Load the API data after mounting so the spinner gets rendered first
        apiCallsCommercial.apiUserSettings()
            .then(userDataObj => {
                isConsultant = userDataObj.IsConsultant
                this.userData = userDataObj
                this.setState({
                    userData: userDataObj,
                    QuoteCustomerMarkupPercent: userDataObj.EndCustomerMarkupFactor !== null ? userDataObj.EndCustomerMarkupFactor * 100 : 125,
                })

                return apiCallsCommercial.apiGetProject(this.state.projectID)
            })
            .then(projectData => {
                // If its a consultant, we display this page with just the project data
                // OR Check if the project data already comes with quote info, also need to make sure the wholesaler order through has not changed
                const existingQuote = (projectData.QuoteCentsIncGST &&
                    (projectData.WholesalerBranchIDOrderedThrough === 0
                        || projectData.WholesalerBranchIDOrderedThrough === this.state.preferredWholesalerID))
                if (isConsultant || existingQuote) {
                    this.setState({
                        quote: projectData,
                        purchaseNumber: projectData.PurchaseNumber,
                        attachedPO: projectData.POIsValid ? 'already attached' : null,
                        promoCode: projectData.QuotePromoCode ? projectData.QuotePromoCode : '',
                        isLoading: { ...this.state.isLoading, app: false }
                    })

                    // Previousely it used to download drawings only for the consultant, but we want all users to see drawing
                    // E.g. Contractor previously needed to place an order before downloading drawings, now he can download them even before placing an order
                    this.getDrawingsFromServer()
                } else {
                    this.setState({
                        quote: projectData
                    }, () => {
                        this.generateQuote().then(() => {
                            // Very first time we need to generate the quote at least once before we can call the drawings API
                            this.getDrawingsFromServer()
                        }).catch(error => {
                            window.alert(error)
                            this.props.history.push(CONST.PAGES.PROJECT_DETAILS + '?orderID=' + this.state.projectID)
                        })
                    })
                }
            })
            .catch(error => {
                this.setState({ apiError: error.message })
                window.setTimeout(() => this.setState({ apiError: '' }), 5000)
            })
    }

    getDrawingsFromServer() {
        this.setState({ isLoading: { ...this.state.isLoading, drawing: true } })

        apiCallsCommercial.apiGetLayoutDiagrams(this.state.projectID)
            .then(response => {
                this.setState({
                    isLoading: { ...this.state.isLoading, drawing: false },
                    drawings: response.ProjectFileCollection.filter(x => x.ProjectBoardID !== -1)
                })
            })
            .catch((error) => {
                this.setState({
                    isLoading: { ...this.state.isLoading, drawing: false },
                    apiError: error.message
                })

                window.setTimeout(() => this.setState({ apiError: '' }), 5000)
            })
    }

    generateQuote() {
        return new Promise((resolve, reject) => {
            // Since the quote company name is required, so we check the fields against that in the project data item
            // if its not already filled in, we will default the values to the values in user setting
            const quoteCompanyName = this.state.quote.QuoteCompanyName ? this.state.quote.QuoteCompanyName : null
            const quoteCustName = this.state.quote.QuoteCompanyName ? this.state.quote.QuoteCustomerName : null
            const quoteAddress = this.state.quote.QuoteCompanyName ? this.state.quote.QuoteCustomerAddress : null
            const quotePhone = this.state.quote.QuoteCompanyName ? this.state.quote.QuoteCustomerPhone : null
            const quoteEmail = this.state.quote.QuoteCompanyName ? this.state.quote.QuoteCustomerEmail : null
            const quoteRef = this.state.quote.QuoteCompanyName ? this.state.quote.QuoteCustomerQuoteRef : null
            const quoteMarkup = this.state.quote.QuoteCompanyName ? this.state.quote.QuoteCustomerMarkupPercent : 125

            apiCallsCommercial.apiGenerateQuote(this.state.projectID, this.state.preferredWholesalerID, quoteCompanyName, quoteCustName, quoteAddress, quotePhone, quoteEmail, quoteRef, quoteMarkup)
                .then(quoteData => {
                    this.setState({
                        quote: quoteData,
                        isLoading: { ...this.state.isLoading, app: false }
                    })

                    analyticsDataLayer.viewQuote({
                        "quote_id": `${this.state.projectID}`,
                        "quote_name": "View quote"
                    })

                    resolve(true)
                })
                .catch(error => {
                    this.setState({ apiError: error.message })
                    window.setTimeout(() => this.setState({ apiError: '' }), 5000)

                    reject(error.message)
                })
        })
    }

    getDisplayQuoteValues = quoteData => {
        const displayQuote = {
            orgExGST: 0,
            incGST: 0,
            exGST: 0,
            GST: 0
        }

        displayQuote.orgExGST = quoteData.QuoteCentsExGST

        if (quoteData.QuoteDiscountedCentsExGST) {
            displayQuote.incGST = quoteData.QuoteDiscountedCentsIncGST
            displayQuote.exGST = quoteData.QuoteDiscountedCentsExGST
            displayQuote.GST = quoteData.QuoteDiscountCentsGST
        } else {
            displayQuote.incGST = quoteData.QuoteCentsIncGST
            displayQuote.exGST = quoteData.QuoteCentsExGST
            displayQuote.GST = quoteData.QuoteCentsGST
        }

        return displayQuote
    }

    getCustomerDisplayQuoteValues = quoteData => {
        const displayQuote = {
            orgExGST: 0,
            incGST: 0,
            exGST: 0,
            GST: 0
        }

        displayQuote.orgExGST = this.getNumericAttr(quoteData, 'CustomerQuoteCentsExGST') ? quoteData.CustomerQuoteCentsExGST : quoteData.QuoteCentsExGST

        if (quoteData.QuoteDiscountedCentsExGST) {
            displayQuote.incGST = this.getNumericAttr(quoteData, 'CustomerDiscountedQuoteCentsIncGST') ? quoteData.CustomerDiscountedQuoteCentsIncGST : quoteData.QuoteDiscountedCentsIncGST
            displayQuote.exGST = this.getNumericAttr(quoteData, 'CustomerDiscountedQuoteCentsExGST') ? quoteData.CustomerDiscountedQuoteCentsExGST : quoteData.QuoteDiscountedCentsExGST
            displayQuote.GST = this.getNumericAttr(quoteData, 'CustomerDiscountedQuoteCentsGST') ? quoteData.CustomerDiscountedQuoteCentsGST : quoteData.QuoteDiscountCentsGST
        } else {
            displayQuote.incGST = this.getNumericAttr(quoteData, 'CustomerQuoteCentsIncGST') ? quoteData.CustomerQuoteCentsIncGST : quoteData.QuoteCentsIncGST
            displayQuote.exGST = this.getNumericAttr(quoteData, 'CustomerQuoteCentsExGST') ? quoteData.CustomerQuoteCentsExGST : quoteData.QuoteCentsExGST
            displayQuote.GST = this.getNumericAttr(quoteData, 'CustomerQuoteCentsGST') ? quoteData.CustomerQuoteCentsGST : quoteData.QuoteCentsGST
        }

        return displayQuote
    }

    getNumericAttr(obj, attribute) {
        return obj.hasOwnProperty(attribute) ? obj[attribute] : 0
    }

    showLeadTimes(board) {
        this.setState({ selectedBoard: board, showBreakdown: true })
        let itemData = []
        board.BoardBOMComponents.forEach(item => {
            itemData.push({
                "item_name": item.Description,
                "item_id": item.ItemCode,
                "item_brand": 'FlexSelect',
                "item_category": item.Category,
                "item_variant": '',
                "price": item.Price,
                "quantity": item.Quantity
            })
        })
        analyticsDataLayer.viewItemList({
            "currency": 'AUD',
            "project_type": 'Commercial',
            "project_name": this.state.quote.Title,
            "item_board_name": board.Name,
            "item_board_id": board.ItemID.toString(),
            "configuration_name": board.Name,
            "configuration_id": board.ItemID.toString(),     
            "project_id": this.state.quote.ItemID.toString(),
            "items": itemData
        })
    }

    hideLeadTimes = () => {
        this.setState({ showBreakdown: false })

        commonFunctionsService.closeModals()
    }

    showEmailCustomerQuote() {
        analyticsDataLayer.ctaButtonClick({
            cta_name: "Email Customer Quote",
            cta_link: window.location.href,
            cta_location: "quote page",
        })
        this.setState({ showEmailCustomerQuoteModal: true })
    }

    hideEmailCustomerQuote = () => {
        this.setState({ showEmailCustomerQuoteModal: false })

        commonFunctionsService.closeModals()
    }

    renderCurrency = cents => {
        const dollars = cents / 100

        return dollars.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }

    renderProjectBoardModals = board => {
        let boardDescription = []
        if (!board.BoardBOMComponents.length) {
            board.ProjectBoardBreakerCollection.forEach(item => {
                const desc = item.BreakerTypeReadOnly.DrawingDesc
                const inArray = boardDescription.find(boardItem => boardItem.Description === desc)
                if (inArray) {
                    boardDescription = boardDescription.map(boardItemObj => {
                        if (boardItemObj.Description === desc) {
                            boardItemObj.Quantity = boardItemObj.Quantity + 1
                        }
                        return boardItemObj
                    })
                } else {
                    boardDescription.push({
                        Quantity: 1,
                        Description: desc,
                        StockAvailability: 'N/A'
                    })
                }
            })
        } else {
            boardDescription = [...board.BoardBOMComponents]
        }

        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Quantity</th>
                        <th>Items</th>
                        <th>Stock</th>
                    </tr>
                </thead>
                <tbody>
                    {boardDescription.map((boardDescriptionObj, index) => {
                        return (
                            <tr key={index}>
                                <td>{boardDescriptionObj.Quantity}</td>
                                <td> {boardDescriptionObj.Description}</td>
                                <td>{boardDescriptionObj.StockAvailability ? boardDescriptionObj.StockAvailability : 'Available'}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    applyPromoCode = () => {
        if (this.state.promoCode.length === 0) {
            this.setState({ validation: { ...this.state.validation, promoCodeInput: true } })
        } else {
            this.setState({
                isLoading: { ...this.state.isLoading, promoCodeApi: true },
                validation: { ...this.state.validation, promoCodeInput: false }
            })

            const applyPromoCodeRequestObj = {
                'ProjectID': this.state.quote.ItemID,
                'PromoCode': this.state.promoCode
            }

            apiCallsCommercial.apiApplyPromoCode(applyPromoCodeRequestObj)
                .then(quoteData => {
                    this.setState({
                        quote: quoteData,
                        attachedPO: quoteData.POIsValid ? '' : null,
                        isLoading: { ...this.state.isLoading, promoCodeApi: false },
                        promoCode: '',
                        showPromoCodeInput: false,
                        pageInfo: 'Promo code applied successfully! Note: Promo code has to be valid at the time of placing order.'
                    })

                    window.setTimeout(() => this.setState({ pageInfo: '' }), 5000)
                })
                .catch(error => {
                    this.setState({
                        apiError: error.message,
                        isLoading: { ...this.state.isLoading, promoCodeApi: false }
                    })

                    window.setTimeout(() => this.setState({ apiError: '' }), 5000)
                })

        }
    }

    handlePromoCode = event => {
        this.setState({ promoCode: event.target.value })
    }

    handlePOUpload = e => {
        e.preventDefault()

        this.setState({ isLoading: { attachingPO: true } })

        // inspiration taken from http://www.hartzis.me/react-image-upload/
        // and https://www.html5rocks.com/en/tutorials/file/dndfiles/
        // should be everything the BEDs need to get the file.

        const reader = new FileReader()
        const fileInput = e.target
        const quoteObj = this.state.quote

        reader.onloadend = () => {
            apiCallsCommercial.apiAttachPo(quoteObj.ItemID, quoteObj.QuoteCentsIncGST, quoteObj.QuoteDiscountedCentsIncGST, reader.result)
                .then(response => {
                    this.setState({
                        attachedPO: response.POFileName === null ? 'this should be set to the return value' : response.POFileName,
                        isLoading: { attachingPO: false },
                        pageInfo: 'PO successfully attached to the project.'
                    })

                    window.setTimeout(() => this.setState({ pageInfo: '' }), 5000)
                })
                .catch(error => {
                    fileInput.value = ''
                    this.setState({
                        apiError: error.message,
                        isLoading: { attachingPO: false }
                    })

                    window.setTimeout(() => this.setState({ apiError: '' }), 5000)
                })
        }

        reader.readAsDataURL(fileInput.files[0])
    }

    // handleCustomerDetailChange = stateObject => {
    //     this.setState({ isLoading: {...this.state.isLoading, updateDetails: true} });
    //     const projectId = this.getUrlParameter('projectID');
    //     let preferredWholesaler = this.getUrlParameter('wholesalerBranchID');
    //     if ( preferredWholesaler === '' ) {
    //         preferredWholesaler = -1; // default value for API
    //     }

    //     apiCallsCommercial.apiGenerateQuote(projectId, preferredWholesaler, stateObject.QuoteCompanyName, stateObject.QuoteCustomerName,
    //         stateObject.QuoteCustomerAddress, stateObject.QuoteCustomerPhone, stateObject.QuoteCustomerEmail, stateObject.QuoteCustomerQuoteRef, stateObject.QuoteCustomerMarkupPercent)
    //         .then(quoteData => {
    //             this.setValidationMsg('Customer details updated successfully.', false);
    //             this.setState({ quote: quoteData });
    //         })
    //         .catch(error => {
    //             this.setValidationMsg(error.message, true);
    //         })
    //         .then(() => {
    //             this.setState({ isLoading: {...this.state.isLoading, updateDetails: false} });
    //             // $.magnificPopup.close();
    //         });
    // }

    // setDispatchDate = date => {
    //     this.setState({
    //         quote: {
    //             ...this.state.quote,
    //             PreferredDispatchDate: date
    //         }
    //     })
    // }

    validateForm = (event) => {
        event.preventDefault()

        // const noPurchaseNumber = this.state.purchaseNumber.length === 0 ? true : false
        // const noAttachedPO = this.state.attachedPO.length === 0 ? true : false

        // Tempoarary disable order requirment
        if (!this.state.userData.UserIsContractor && false) {
            // if (!this.state.userData.UserIsContractor && (noPurchaseNumber || noAttachedPO)) {
            this.setState({
                validation: {
                    ...this.state.validation
                    // ,
                    // purchaseOrderNumber: noPurchaseNumber,
                    // noAttachedPO: noAttachedPO
                }
            })
        } else {
            this.setState({
                showConfirmationModal: true,
                validation: {
                    ...this.state.validation
                    // ,
                    // purchaseOrderNumber: false
                }
            })
        }
    }

    hideConfirmationModal() {
        this.setState({ showConfirmationModal: false }, () => jQuery('.app__body-content').animate({ scrollTop: 0 }, 'fast'))

        commonFunctionsService.closeModals()
    }

    async downloadDrawings() {
        let drawingsDownloaded = 0
        const numberOfDrawings = this.state.drawings.length
        const token = localStorage.getItem('token')
    
        const downloadDrawing = async (drawing) => {
            const downloadURL = `${apiUrlGetDocument}/${drawing.ItemGUID}`
            const response = await fetch(downloadURL, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
    
            let contentDisposition = response.headers.get('Content-Disposition')
            // Remove all quotes " from the contentDisposition, as some .NET behaviour adds them 
            // it's because of the special characters "(" and ")" in case of the project names with brackets
            contentDisposition = contentDisposition.replace(/"/g, '')
            let filename = 'download.pdf'
            if (contentDisposition && contentDisposition.includes('attachment')) {
                const matches = /filename=([^;]+)/.exec(contentDisposition)
                if (matches && matches[1]) {
                    filename = matches[1]
                }
            }
    
            const a = document.createElement('a')
            a.href = url
            a.download = filename
            a.click()
            window.URL.revokeObjectURL(url)
    
            analyticsDataLayer.downloadLayout({
                "document_id": drawing.ItemGUID,
                "document_name": "Download Drawings",
                "document_type": "pdf"
            })
        }
    
        const downloadNext = async () => {
            if (drawingsDownloaded < numberOfDrawings) {
                await downloadDrawing(this.state.drawings[drawingsDownloaded])
                drawingsDownloaded++
                setTimeout(downloadNext, 500)
            }
        }
    
        downloadNext()
    }

    saveProject = (event) => {
        event.preventDefault()

        this.setState({
            isLoading: { ...this.state.isLoading, placeOrderApi: true },
            quote: { ...this.state.quote, QuoteCustomerMarkupPercent: this.state.QuoteCustomerMarkupPercent } // Save eventually changed mark-up on the front-end
        }, () => {
            const saveProjectRequestObj = {
                ...cloneDeep(this.state.quote).value()
            }

            saveProjectRequestObj['PurchaseNumber'] = this.state.purchaseNumber
            saveProjectRequestObj['DontResetQuote'] = true

            if (this.state.userData.UserIsContractor) {
                saveProjectRequestObj['WholesalerBranchIDOrderedThrough'] = parseInt(this.state.selectedWholesalerIDForProduction, 10)
            }

            apiCallsCommercial.apiSave(saveProjectRequestObj)
                .then(() => {
                    // Once we save the project we submit the order for the contractor
                    this.submitOrder()
                })
                .catch(error => {
                    this.setState({ apiError: error.message })
                    window.setTimeout(() => this.setState({ apiError: '' }), 5000)
                })
        })
    }

    sendEmailCustomerQuote = (event) => {
        event.preventDefault()

        this.setState({ isLoading: { ...this.state.isLoading, emailQuote: true } })

        // Before we send an email we need to save provided customer data by calling Project quote API
        apiCallsCommercial.apiGenerateQuote(this.state.projectID, this.state.preferredWholesalerID,
            this.state.QuoteCompanyName,
            this.state.QuoteCustomerName,
            '',
            '',
            this.state.QuoteCustomerEmail,
            this.state.QuoteCustomerQuoteRef,
            this.state.QuoteCustomerMarkupPercent)
            .then(quoteData => {
                this.setState({
                    quote: quoteData
                })

                apiCallsCommercial.apiEmailQuote(this.state.quote.ItemID, true)
                    .then(() => {
                        this.setState({ pageInfo: 'Quote email sent successfully.' })

                        analyticsDataLayer.emailQuote({
                            quote_id: `${this.state.projectID}`,
                            quote_name: "Send Quote",
                            currency: "AUD",
                            total_amount: quoteData.CustomerQuoteCentsIncGST / 100,
                            markup_percentage: `${this.state.QuoteCustomerMarkupPercent - 100}%`
                        })
                        window.setTimeout(() => this.setState({ pageInfo: '' }), 5000)
                    })
                    .catch(error => {
                        this.setState({ apiError: error.message })
                        window.setTimeout(() => this.setState({ apiError: '' }), 5000)
                    })
                    .then(() => {
                        this.setState({ isLoading: { ...this.state.isLoading, emailQuote: false } })
                        this.hideEmailCustomerQuote()
                    })
            })
            .catch(error => {
                this.setState({ apiError: error.message })
                window.setTimeout(() => this.setState({ apiError: '' }), 5000)
            })
    }

    submitOrder() {
        this.setState({
            isLoading: { ...this.state.isLoading, placeOrderApi: true }
        })

        apiCallsCommercial.apiSendOrder(this.state.projectID)
            .then(() => {
                this.hideConfirmationModal()

                let projectBoards = []
                let newProjectBoardDetails = []
                this.state.quote.ProjectBoards.forEach(board => {
                    board.BoardBOMComponents.forEach(item => {
                        const baseEventDetails = {
                            'item_id': item.ItemCode,
                            'item_name': item.Description,
                            'item_brand': 'FlexSelect',
                            'item_category': item.Category,
                            'item_variant': '',
                            'price': item.Price,
                            'quantity': item.Quantity
                        }

                        // Old event details we still need to keep
                        projectBoards.push({
                            ...baseEventDetails,
                            item_board_name: board.Name,
                            item_board_id: board.ItemID.toString()
                        })

                        // New event details with new parameters configuration_name and configuration_id
                        newProjectBoardDetails.push({
                            ...baseEventDetails,
                            configuration_name: board.Name,
                            configuration_id: board.ItemID.toString()
                        })
                    })
                })

                // As part of new requirments we trigger the purchase event and also add to cart event at the same time
                // So this is intentional and not a mistake
                analyticsDataLayer.purchase({
                    'currency': 'AUD',
                    'transaction_id': this.state.quote.ProjectNumberReadOnly,
                    'value': this.state.quote.QuoteCentsIncGST / 100,
                    'project_type': 'Commercial',
                    'project_name': this.state.quote.Title,
                    'project_id': this.state.quote.ItemID.toString(),
                    'tax': this.state.quote.QuoteCentsGST ? this.state.quote.QuoteCentsGST / 100 : 0,
                    'order_number': this.state.purchaseNumber,
                    'items': projectBoards
                })

                analyticsDataLayer.addToCart({
                    'currency': 'AUD',
                    'value': this.state.quote.QuoteCentsIncGST / 100,
                    'project_type': 'Commercial',
                    'project_name': this.state.quote.Title,
                    'project_id': this.state.quote.ItemID.toString(),
                    'order_number': this.state.purchaseNumber,
                    'items': newProjectBoardDetails
                }, 'place order')

                // After the order is placed we redirect the user back to the project details, that page at this stage should indicate completly different state
                this.props.history.push(CONST.PAGES.PROJECT_DETAILS + '?orderID=' + this.state.projectID)
            })
            .catch(error => {
                this.hideConfirmationModal()

                this.setState({
                    isLoading: { ...this.state.isLoading, placeOrderApi: false },
                    apiError: error.message
                })
                window.setTimeout(() => this.setState({ apiError: '' }), 5000)
            })
    }

    // Send email to each wholesaler selected
    requestWholesalersQuote() {
        this.setState({ apiError: '' })

        if (this.state.wholesalerToSendQuote === '') {
            this.setState({ apiError: 'You need to select a wholesaler to request a quote' })
            window.setTimeout(() => this.setState({ apiError: '' }), 5000)
        } else {
            this.setState({ isLoading: { ... this.state.isLoading, emailQuote: true } })

            apiCallsCommercial.apiEmailQuote(this.state.quote.ItemID, false, [this.state.wholesalerToSendQuote])
                .then(() => {
                    // After sending a quote to one or multiple wholselers we need to display a message and show "Proceed with production" section
                    this.setState({ hasRequestedEmailQuote: true, contractorRequestAgain: true })
                })
                .catch(error => {
                    this.setState({ apiError: error.message })
                    window.setTimeout(() => this.setState({ apiError: '' }), 5000)
                })
                .then(() => {
                    this.setState({ isLoading: { ... this.state.isLoading, emailQuote: false } })
                    window.setTimeout(() => this.setState({ hasRequestedEmailQuote: false }), 2500)
                })
        }
    }

    changeWholesalerMarkup(increase) {
        let markup = 0
        if (increase) {
            markup = this.state.QuoteCustomerMarkupPercent + 1
        } else {
            markup = this.state.QuoteCustomerMarkupPercent - 1
        }

        this.setState({ QuoteCustomerMarkupPercent: markup })
    }

    renderOrderItemTable(quote, quoteValue100, isSwitchManOrLargeContractor) {
        console.log('quote', quote, quoteValue100)
        return !this.state.userData.UserIsContractor &&
            <div className="l_quote-col">
                <div className="quote-bg">
                    <div className="quote__header">
                        {isSwitchManOrLargeContractor ?
                            <div className='quote__header--removeSellPrice'>
                                <h3 className="quote__title h3">
                                    <span className="u_text-gap-right">
                                        {quote.Title}
                                    </span>
                                    <span className="header-number">
                                        {quote.ProjectNumberReadOnly}
                                    </span>
                                </h3>
                                <button className="button button--1 email-customer-quote"
                                    onClick={() => this.showEmailCustomerQuote()}>
                                    <span className="button__text">
                                        Email customer quote
                                    </span>
                                </button>
                            </div> :
                            <h3 className="quote__title h3">
                                <span className="u_text-gap-right">
                                    {quote.Title}
                                </span>
                                <span className="header-number">
                                    {quote.ProjectNumberReadOnly}
                                </span>
                            </h3>
                        }
                        <div className={`quote-table quote-table--1 ${isSwitchManOrLargeContractor ? 'quote-table--removeSellPrice' : ''}`}>
                            {!isSwitchManOrLargeContractor ?
                                <div className="quote-table__sell-price">Sell price</div>
                                : null
                            }
                            <div className={`quote-table__row quote-table__header quote-table__header--wholesaler-n-manufacturer ${isSwitchManOrLargeContractor ? 'quote-table__row--removeSellPrice' : ''}`}>
                                <div className="boards">
                                    Boards
                                </div>
                                <div className="price">
                                    <span>Price <span className="u_mute-text">(Ex GST)</span></span>
                                </div>
                                <div className="gst">
                                    GST
                                </div>
                                <div className="total">
                                    <span>Total Price <span className="u_mute-text">(Inc. GST)</span></span>
                                </div>
                                {!isSwitchManOrLargeContractor ?
                                    <div className="markup">
                                        Mark-up
                                        <div className="markup-control">
                                            <button type="button" className="button button--markup decrease"
                                                onClick={() => this.changeWholesalerMarkup(false)}>
                                                <span className="button__icon">
                                                    <span className="i-remove"></span>
                                                </span>
                                            </button>

                                            <div className="markup-control__amount">
                                                <span className="markup-control__amount-text">
                                                    {this.state.QuoteCustomerMarkupPercent - 100}%
                                                </span>
                                            </div>

                                            <button type="button" className="button button--markup increase"
                                                onClick={() => this.changeWholesalerMarkup(true)}>
                                                <span className="button__icon">
                                                    <span className="i-plus"></span>
                                                </span>
                                            </button>
                                        </div>
                                    </div> : null
                                }
                            </div>

                            {quote?.ProjectBoards?.map(board => {
                                const boardValues = this.getDisplayQuoteValues(board)
                                return (
                                    <div className={`quote-table__row ${isSwitchManOrLargeContractor ? 'quote-table__row--removeSellPrice' : ''}`} key={board.ItemID}>
                                        <div className="boards">
                                            <div>
                                                <span className="u_text-gap-right">
                                                    {board.Name}
                                                </span>
                                                <button className="button button--outline" onClick={() => { this.showLeadTimes(board) }}>
                                                    <span className="button__text">
                                                        View lead times
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="price">
                                            {board.QuoteDiscountedCentsExGST ? (
                                                <span className="promo-price">
                                                    <span className="new-price">{this.renderCurrency(boardValues.exGST)}</span>
                                                    <span className="old-price">Was {this.renderCurrency(boardValues.orgExGST)}</span>
                                                </span>
                                            ) : (
                                                this.renderCurrency(boardValues.exGST)
                                            )}
                                        </div>
                                        <div className="gst">
                                            {this.renderCurrency(boardValues.GST)}
                                        </div>
                                        <div className="total">
                                            {this.renderCurrency(boardValues.incGST)}
                                        </div>
                                        {!isSwitchManOrLargeContractor ?
                                            <div className="markup">
                                                {this.renderCurrency(boardValues.exGST * this.state.QuoteCustomerMarkupPercent / 100)} + GST
                                                <span className="small">
                                                    {this.renderCurrency((boardValues.exGST * this.state.QuoteCustomerMarkupPercent / 100) - boardValues.exGST)} (Mark-up)
                                                </span>
                                            </div> : null
                                        }
                                    </div>
                                )
                            })}

                            <div className={`quote-table__row ${isSwitchManOrLargeContractor ? 'quote-table__row--removeSellPrice' : ''}`}>
                                <div className="boards">
                                    <div>
                                        <span className="u_text-gap-right">
                                            Total
                                        </span>
                                        <form
                                            className={'form pc' +
                                                (this.state.showPromoCodeInput ? ' is-active' : '') +
                                                (this.state.validation.promoCodeInput ? ' is-error' : '')
                                            }>
                                            <button type="button" className={'pc__button button button--clear' + (this.state.isLoading.promoCodeApi ? ' disabled' : '')}
                                                onClick={() => this.setState({ showPromoCodeInput: true })}>
                                                <span className="button__icon">
                                                    <span className="i-tag"></span>
                                                </span>
                                                <span className="button__text">
                                                    Add promo code
                                                </span>
                                            </button>

                                            <div className="pc__input-container">
                                                <input className="input form__input" placeholder="Enter promo code" type="text" required
                                                    defaultValue={this.state.promoCode ? this.state.promoCode : ''} onChange={this.handlePromoCode} disabled={this.state.isLoading.promoCodeApi} />

                                                <button type="button" className="button button--clear" onClick={this.applyPromoCode}>
                                                    <span className="button__text">
                                                        Apply
                                                    </span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="price">
                                    {this.renderCurrency(quoteValue100.exGST)}
                                </div>
                                <div className="gst">
                                    {this.renderCurrency(quoteValue100.GST)}
                                </div>
                                <div className="total">
                                    {this.renderCurrency(quoteValue100.incGST)}
                                </div>
                                {!isSwitchManOrLargeContractor ?
                                    <div className="markup">
                                        {this.renderCurrency(quoteValue100.exGST * this.state.QuoteCustomerMarkupPercent / 100)} + GST
                                        <span className="small">
                                            {this.renderCurrency((quoteValue100.exGST * this.state.QuoteCustomerMarkupPercent / 100) - quoteValue100.exGST)} (Mark-up)
                                        </span>
                                        <button className="button button--1 email-customer-quote"
                                            onClick={() => this.showEmailCustomerQuote()}>
                                            <span className="button__text">
                                                Email customer quote
                                            </span>
                                        </button>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    }

    renderMarkup(isSwitchManOrLargeContractor) {
        if (!isSwitchManOrLargeContractor) {
            return ` (${this.state.QuoteCustomerMarkupPercent - 100}% Mark-up)`
        }
        return null
    }


    render() {
        const isSwitchManOrLargeContractor = ['SwitchboardManufacturer', 'LargeContractor'].includes(this.state.userData.UserTypeString)
        if (this.state.isLoading.app) {
            return (
                <GlobalOverlay>
                    <div>
                        <div className="globalOverlay__message">
                            <div className="globalOverlay__message-success-icon">
                                <span className="i-document"></span>
                            </div>
                            <span className="h3">Generating Quote</span>
                        </div>
                    </div>
                </GlobalOverlay>)
        } else {
            const quote = this.state.quote
            const quoteValue = this.getCustomerDisplayQuoteValues(quote)
            const quoteValue100 = this.getDisplayQuoteValues(quote)

            return (
                <React.Fragment>
                    <div className="app">

                        {
                            this.state.isLoading.placeOrderApi &&
                            <GlobalOverlay>
                                <div>
                                    <div className="globalOverlay__message">
                                        <span className="globalOverlay__message-spinner">
                                            <span className="spinner"></span>
                                        </span>
                                        <span className="h3">Placing Order</span>
                                    </div>
                                </div>
                            </GlobalOverlay>
                        }

                        {
                            this.state.isLoading.emailQuote &&
                            <GlobalOverlay>
                                <div>
                                    <div className="globalOverlay__message">
                                        <span className="globalOverlay__message-spinner">
                                            <span className="spinner"></span>
                                        </span>
                                        <span className="h3">Sending Quote</span>
                                    </div>
                                </div>
                            </GlobalOverlay>
                        }

                        {
                            this.state.hasRequestedEmailQuote &&
                            <GlobalOverlay>
                                <div>
                                    <div className="globalOverlay__message">
                                        <span className="globalOverlay__message-success-icon">
                                            <span className="i-check"></span>
                                        </span>
                                        <span className="h3">Quote sent</span>
                                    </div>
                                </div>
                            </GlobalOverlay>
                        }

                        {/* Email Quote */}
                        {this.state.showEmailCustomerQuoteModal &&
                            <Modal
                                modalClassName="modal modal--sm modal--sm-header"
                                modalTitle="Email Quote"
                                callback={this.hideEmailCustomerQuote}
                            >
                                <form className="form" onSubmit={this.sendEmailCustomerQuote}>
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="email-quote__lead">
                                                For the amount of <span className="h5">
                                                    {this.renderCurrency(quoteValue100.exGST * (isSwitchManOrLargeContractor ? 100 : this.state.QuoteCustomerMarkupPercent) / 100)} + GST
                                                    {this.renderMarkup(isSwitchManOrLargeContractor)}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form__group form__group--native-validation">
                                                <input id="quote-customer-name" className="form__input input" type="text" required
                                                    value={this.state.QuoteCustomerName}
                                                    onChange={(event) => this.setState({ QuoteCustomerName: event.target.value })} />
                                                <label className="form__label" htmlFor="quote-customer-name">
                                                    Customer Name
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form__group form__group--native-validation">
                                                <input id="quote-company-name" className="form__input input" type="text" required
                                                    value={this.state.QuoteCompanyName}
                                                    onChange={(event) => this.setState({ QuoteCompanyName: event.target.value })} />
                                                <label className="form__label" htmlFor="quote-company-name">
                                                    Company Name
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form__group form__group--native-validation">
                                                <input id="quote-email" className="form__input input" type="email" required
                                                    value={this.state.QuoteCustomerEmail}
                                                    onChange={(event) => this.setState({ QuoteCustomerEmail: event.target.value })} />
                                                <label className="form__label" htmlFor="quote-email">
                                                    Email Address
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal__footer">
                                        <button className="button button--1">
                                            <span className="button__text">
                                                Send Quote
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </Modal>
                        }

                        {/* Confirm Order */}
                        {
                            this.state.showConfirmationModal &&
                            <Modal
                                modalClassName="modal modal--sm-header"
                                modalTitle="Confirm order placement"
                                callback={this.hideConfirmationModal}
                            >
                                <form className="form" onSubmit={this.saveProject}>
                                    <div className="form__group">
                                        <div className="form__control checkbox">
                                            <input id="order-terms" type="checkbox" name="Order Terms"
                                                className="form__control-input" required checked={this.state.orderPlacementCondition1Accepted}
                                                onChange={(event) => this.setState({ orderPlacementCondition1Accepted: event.target.checked })} />
                                            <label htmlFor="order-terms" className="form__control-label">By placing an order you agree to our <a href="https://www.se.com/au/en/about-us/legal/terms-and-conditions.jsp" target="_blank" rel="nofollow noopener">Terms and Conditions</a></label>
                                        </div>
                                        <div className="form__control checkbox">
                                            <input id="order-GCR-solution" type="checkbox" name="order GCR solution"
                                                className="form__control-input" required checked={this.state.orderPlacementCondition2Accepted}
                                                onChange={(event) => this.setState({ orderPlacementCondition2Accepted: event.target.checked })} />
                                            <label htmlFor="order-GCR-solution" className="form__control-label">This item is a GCR solution and is not refundable</label>
                                        </div>
                                        <div className="form__control checkbox">
                                            <input id="order-production" type="checkbox" name="order Production"
                                                className="form__control-input" required checked={this.state.orderPlacementCondition3Accepted}
                                                onChange={(event) => this.setState({ orderPlacementCondition3Accepted: event.target.checked })} />
                                            <label htmlFor="order-production" className="form__control-label">Production will be immediately initiated in accordance with the drawings created based on your configuration(s).</label>
                                        </div>
                                        <div className="form__control checkbox">
                                            <input id="order-changes" type="checkbox" name="order changes"
                                                className="form__control-input" required checked={this.state.orderPlacementCondition4Accepted}
                                                onChange={(event) => this.setState({ orderPlacementCondition4Accepted: event.target.checked })} />
                                            <label htmlFor="order-changes" className="form__control-label">Changes are not permitted</label>
                                        </div>
                                        <div className="form__control checkbox">
                                            <input id="order-acceptance" type="checkbox" name="order acceptance"
                                                className="form__control-input" required checked={this.state.orderPlacementCondition5Accepted}
                                                onChange={(event) => this.setState({ orderPlacementCondition5Accepted: event.target.checked })} />
                                            <label htmlFor="order-acceptance" className="form__control-label">Order placement subject to acceptance of order by Schneider Electric</label>
                                        </div>
                                    </div>

                                    <div className="modal__footer">
                                        <button type="button" className="button button--back" onClick={() => this.hideConfirmationModal()}>
                                            <span className="button__text">
                                                Back
                                            </span>
                                        </button>
                                        <button type="submit" className="button button--1" disabled={
                                            !this.state.orderPlacementCondition1Accepted ||
                                            !this.state.orderPlacementCondition2Accepted ||
                                            !this.state.orderPlacementCondition3Accepted ||
                                            !this.state.orderPlacementCondition4Accepted ||
                                            !this.state.orderPlacementCondition5Accepted
                                        }>
                                            <span className="button__text">
                                                Proceed
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </Modal>
                        }

                        {/* Item Breakdown */}
                        {
                            this.state.showBreakdown &&
                            <Modal
                                modalClassName="modal--md modal--sm-header"
                                modalTitle="Item Breakdown"
                                callback={this.hideLeadTimes}
                            >
                                {this.renderProjectBoardModals(this.state.selectedBoard)}

                                <p className="p p--xs">
                                    *Longer lead times may apply
                                </p>
                            </Modal>
                        }

                        <Header isCommercial={true}>
                            <div className="header__section hs_breadcrumbs">
                                <div className="breadcrumbs">
                                    <Link className="breadcrumb" to="/">My Commercial Projects</Link>
                                    <Link className="breadcrumb" to={CONST.PAGES.PROJECT_DETAILS + '?orderID=' + this.state.projectID}>{this.state.quote.Title}</Link>
                                    <span className="breadcrumb">Quote</span>
                                </div>
                            </div>
                            <div className="header__section hs_quote">
                                <h2 className="h3 header__title">
                                    <span className="u_text-gap-right">
                                        {quote.Title}
                                    </span>
                                    <span className="header-number">
                                        {quote.ProjectNumberReadOnly}
                                    </span>
                                </h2>
                            </div>
                        </Header>


                        <div className="app__body">
                            <div className="app__body-content">

                                <div className="alert-container alert-container-quote">
                                    {
                                        this.state.pageInfo !== '' &&
                                        <p className="alert alert--success">
                                            <span className="alert__icon">
                                                <span className="i-check-circle"></span>
                                            </span>
                                            <span className="alert__text">
                                                {this.state.pageInfo}
                                            </span>
                                        </p>
                                    }

                                    {
                                        this.state.apiError !== '' &&
                                        <p className="alert alert--error">
                                            <span className="alert__icon">
                                                <span className="i-exclamation"></span>
                                            </span>
                                            <span className="alert__text">
                                                {this.state.apiError}
                                            </span>
                                        </p>
                                    }
                                </div>

                                {this.state.userData.IsConsultant &&
                                    <div className="quote-layout">
                                        <div className="l_quote-col">
                                            <div className="quote-bg">
                                                <div className="quote__header">
                                                    <h3 className="quote__title h3">
                                                        <span className="u_text-gap-right">
                                                            {quote.Title}
                                                        </span>
                                                        <span className="header-number">
                                                            {quote.ProjectNumberReadOnly}
                                                        </span>
                                                    </h3>
                                                    <div className="quote-table quote-table--consultant quote-table-consultant">
                                                        <div className="quote-table__row quote-table__header">
                                                            <div className="boards">
                                                                Boards
                                                            </div>
                                                        </div>

                                                        {quote.ProjectBoards.map(board => {
                                                            return (
                                                                <div className="quote-table__row" key={board.ItemID}>
                                                                    <div className="boards">
                                                                        <div>
                                                                            <span className="u_text-gap-right">
                                                                                {board.Name}
                                                                            </span>
                                                                            <button className="button button--outline" onClick={() => { this.showLeadTimes(board) }}>
                                                                                <span className="button__text">
                                                                                    View lead times
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="l_quote-col">
                                            <div className="quote__review">
                                                <button className={'button button--2' + (this.state.isLoading.drawing || this.state.drawings.length === 0 ? ' disabled' : '')}
                                                    onClick={() => this.downloadDrawings()}>
                                                    <span className="button__icon">
                                                        <span className="i-download"></span>
                                                    </span>
                                                    <span className="button__text">
                                                        {
                                                            this.state.isLoading.drawing ?
                                                                'Loading Drawings'
                                                                :
                                                                this.state.drawings.length === 0 ? 'No Drawings Available' : 'Download Drawings'
                                                        }
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }


                                {/* All cases that are not consultants */}
                                {!this.state.userData.IsConsultant &&
                                    <div className="quote-layout">

                                        {/* Table view for contractors */}
                                        {
                                            this.state.userData.UserIsContractor &&
                                            <div className="l_quote-col">
                                                <div className="quote-bg">
                                                    <div className="quote__header">
                                                        <h3 className="quote__title h3">
                                                            <span className="u_text-gap-right">
                                                                {quote.Title}
                                                            </span>
                                                            <span className="header-number">
                                                                {quote.ProjectNumberReadOnly}
                                                            </span>
                                                        </h3>

                                                        <h4 className="h4">Retail Pricing</h4>
                                                        <p>Please note this is RRP, please request a quote from your wholesaler to confirm the cost</p>

                                                        <div className="quote-table quote-table--2">
                                                            <div className="quote-table__row quote-table__header">
                                                                <div className="boards">
                                                                    Boards
                                                                </div>

                                                                <div className="markup">
                                                                    <span>RRP <span className="u_mute-text">(Excl. GST)</span></span>
                                                                </div>
                                                            </div>

                                                            {quote.ProjectBoards.map(board => {
                                                                const boardValues = this.getCustomerDisplayQuoteValues(board)
                                                                return (
                                                                    <div className="quote-table__row" key={board.ItemID}>
                                                                        <div className="boards">
                                                                            <div>
                                                                                <span className="u_text-gap-right">
                                                                                    {board.Name}
                                                                                </span>
                                                                                <button className="button button--outline" onClick={() => { this.showLeadTimes(board) }}>
                                                                                    <span className="button__text">
                                                                                        View lead times
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                        <div className="markup">
                                                                            {this.renderCurrency(boardValues.exGST)}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}

                                                            <div className="quote-table__row">
                                                                <div className="boards">
                                                                    <div>
                                                                        <span className="u_text-gap-right">
                                                                            Total
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="markup">
                                                                    {this.renderCurrency(quoteValue.exGST)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* Table view for Wholeselers and Switch Board Manufacturers and hide the Sell price option for SwitchMan and LargeContractor */}
                                        {this.renderOrderItemTable(quote, quoteValue100, isSwitchManOrLargeContractor)}

                                        <div className="l_quote-col" >
                                            <div className="quote__review">
                                                <h4 className="h4">Review</h4>

                                                <button className={'button button--2' + (this.state.isLoading.drawing || this.state.drawings.length === 0 ? ' disabled' : '')}
                                                    onClick={() => this.downloadDrawings()}>
                                                    <span className="button__icon">
                                                        <span className="i-download"></span>
                                                    </span>
                                                    <span className="button__text">
                                                        {
                                                            this.state.isLoading.drawing ?
                                                                'Loading Drawings'
                                                                :
                                                                this.state.drawings.length === 0 ? 'No Drawings Available' : 'Download Drawings'
                                                        }
                                                    </span>
                                                </button>

                                                {
                                                    this.state.userData.UserIsContractor &&
                                                    <div>
                                                        {
                                                            !this.state.contractorRequestAgain &&
                                                            <div className="quote__review-step">
                                                                <h4 className="h4 quote__review-step__title">
                                                                    Request Quote

                                                                    <button className="button button--clear" onClick={() => this.setState({ contractorRequestAgain: true })}>
                                                                        <span className="buton__text">I have one</span>
                                                                        <span className="button__icon">
                                                                            <span className="i-document"></span>
                                                                        </span>
                                                                    </button>
                                                                </h4>

                                                                <p className="p p--sm">
                                                                    Select the wholesalers to request a quote
                                                                </p>

                                                                <div className="form">
                                                                    <div className="form__group">
                                                                        <div className="form__group form__group--native-validation">
                                                                            <select className="form__select select--custom" name="state" id="selected-wholesaler-production" required value={this.state.wholesalerToSendQuote}
                                                                                onChange={(event) => this.setState({ wholesalerToSendQuote: parseInt(event.target.value, 10) })}>
                                                                                <option value="" hidden>Please Select</option>
                                                                                {
                                                                                    this.state.userData.PreferredWholesalers.map(wholesaler => {
                                                                                        return (
                                                                                            <option key={wholesaler.WholesalerBranchID} value={wholesaler.WholesalerBranchID}>{wholesaler.WholesalerBranchName}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <button type="submit" className="button button--1" onClick={this.requestWholesalersQuote}>
                                                                        <span className="button__icon">
                                                                            <span className="i-document"></span>
                                                                        </span>
                                                                        <span className="button__text">
                                                                            Request quote
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }

                                                        {
                                                            this.state.contractorRequestAgain &&
                                                            <div className="quote__review-step has-border">

                                                                <h4 className="h4 quote__review-step__title is-done">
                                                                    <span className="form-label-step" htmlFor="board-create-name">
                                                                        <div className="form-label-step__icon">
                                                                            <span className="i-check"></span>
                                                                        </div>
                                                                        <span className="form-label-step__text">
                                                                            Quotes requested
                                                                        </span>
                                                                    </span>
                                                                    <button className="button button--clear" onClick={() => this.setState({ contractorRequestAgain: false })}>
                                                                        <span className="buton__text">Request again</span>
                                                                    </button>
                                                                </h4>
                                                                <h4 className="h4 quote__review-step__title">
                                                                    Proceed with production
                                                                </h4>
                                                                <p className="p p--sm">
                                                                    Once you have an agreed price set with your Wholesaler
                                                                </p>

                                                                <form className="form" onSubmit={this.validateForm}>
                                                                    <div className="form__group">
                                                                        <div className="form__group form__group--native-validation">
                                                                            <select className="form__select select--custom" name="state" id="selected-wholesaler-production" required value={this.state.selectedWholesalerIDForProduction}
                                                                                onChange={(event) => this.setState({ selectedWholesalerIDForProduction: event.target.value })}>
                                                                                <option value="" hidden>Please Select</option>
                                                                                {
                                                                                    this.state.userData.PreferredWholesalers.map(wholesaler => {
                                                                                        return (
                                                                                            <option key={wholesaler.WholesalerBranchID} value={wholesaler.WholesalerBranchID}>{wholesaler.WholesalerBranchName}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>

                                                                            <label className="form__label p--sm" htmlFor="selected-wholesaler-production">Selected Wholesaler</label>
                                                                        </div>
                                                                    </div>

                                                                    <button type="submit" className="button button--1">
                                                                        <span className="button__icon">
                                                                            <span className="i-document"></span>
                                                                        </span>
                                                                        <span className="button__text">
                                                                            Place Order
                                                                        </span>
                                                                    </button>

                                                                </form>
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                                {
                                                    !this.state.userData.UserIsContractor &&
                                                    <React.Fragment>
                                                        <h4 className="h4">Proceed with production</h4>
                                                        <p className="p p--sm">
                                                            Lead times are 5 business days to dispatch for most selections
                                                        </p>

                                                        <form className="form">
                                                            {/* Requirments keep changing, if this won't be needed once deployed to production, then please remove this and all references */}
                                                            {/* <Datepicker
                                                    datepickerID="dispath-date"
                                                    labelText="Preferred Dispatch Date"
                                                    placeholderText="DD/MM/YY"
                                                    handleDate={this.setDispatchDate}
                                                    defaultDate={this.state.quote.PreferredDispatchDate}
                                                /> */}

                                                            <div className={`form__group ${this.state.validation.purchaseOrderNumber ? ' is-error' : ''}`}>
                                                                <label className="form__label p--sm" htmlFor="">
                                                                    Purchase Order Number
                                                                </label>
                                                                <input className="form__input input" placeholder="eg. 000067" type="text" maxLength="35"
                                                                    value={this.state.purchaseNumber ? this.state.purchaseNumber : ''}
                                                                    onChange={(event) => this.setState({ purchaseNumber: event.target.value })} />
                                                                <span className="form__group-error p--xs p--700">Please enter a Purchase Order Number</span>
                                                            </div>

                                                            <div className={`form__group ${this.state.validation.purchaseOrderNumber ? ' is-error' : ''}`}>
                                                                <div className={'form__upload' + (this.state.isLoading.attachingPO ? ' is-loading' : '')}>
                                                                    <input className="form__upload-input" type="file" onChange={this.handlePOUpload} />
                                                                    <span className="form__upload-ui">
                                                                        <span className="i-upload"></span>
                                                                        {
                                                                            this.state.attachedPO === '' ?
                                                                                <span>Upload Purchase Order</span>
                                                                                :
                                                                                <span>File attached</span>
                                                                        }
                                                                    </span>
                                                                </div>

                                                                <span className="form__group-error p--xs p--700">Please upload a Purchase Order</span>
                                                            </div>

                                                            <p className="p p--sm">
                                                                For the amount of <span className="em">
                                                                    {this.renderCurrency(quoteValue100.exGST)} + GST
                                                                </span>
                                                            </p>

                                                            <button type="button" className="button button--1" onClick={this.validateForm}>
                                                                <span className="button__icon">
                                                                    <span className="i-document"></span>
                                                                </span>
                                                                <span className="button__text">
                                                                    Place Order
                                                                </span>
                                                            </button>
                                                        </form>
                                                    </React.Fragment>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default withRouter(Quote)