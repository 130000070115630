import React from 'react'
import jQuery from 'jquery'
import cloneDeep from 'lodash.clonedeep'
import analyticsDataLayer from '../../../services/AnalyticsDataLayer'

export default class ChassisBoard extends React.Component {
    constructor(props) {
        super(props)

        // Bind this to element function calls for better performance / practise
        // - ref: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
        this.updateCall = this.updateCall.bind(this)

        // Internal states that no one else needs to know about. Holds info on the selection options.
        this.initStates(this.props)
    }

    initStates(propsData, useSetState = false) {
        const updatedState = {
            selectedParts: cloneDeep(propsData.selected)
        }

        if (useSetState) {
            this.setState(updatedState)
        } else {
            this.state = updatedState
        }
    }

    componentWillReceiveProps(nextProps) {
        this.initStates(nextProps, true)

        if (nextProps.isOpen) {
            jQuery('body').addClass('loose-items-is-open')
        } else {
            this.closePanel()
        }
    }

    closePanel() {
        jQuery('body').removeClass('loose-items-is-open')
    }

    updateCall() {
        // We actually don't need to show any loading indicator that we are saving those loose items, we just close the modal
        this.closePanel()

        // Calls create / update callback based on is new breaker flag
        this.props.updateCallback(this.state.selectedParts)

    }

    getPartSelectedQty(id) {
        if (!this.state.selectedParts || !this.state.selectedParts.length) { return 0 }

        const checkPart = this.state.selectedParts.find(item => item.LooseSupplyItemItemID === id)
        if (!checkPart) { return 0 }

        return checkPart.Quantity
    }

    getTotalSelectedQuantity() {
        let totalSelectedQuantity = 0
        this.props.partsList.forEach(opt => {
            totalSelectedQuantity = totalSelectedQuantity + this.getPartSelectedQty(opt.ItemID)
        })

        return totalSelectedQuantity
    }

    // Update the selected option value in local state
    updateSelectedPart(id, mod, type) {
        // If the part is already in the selected list, changes its quantity
        const updatedParts = this.state.selectedParts.length ? cloneDeep(this.state.selectedParts) : []
        const partInfo = this.props.partsList.find(item => item.ItemID === id)
        let foundPart = false
        let removeIndex = -1
        if (updatedParts.length) {
            updatedParts.map((item, index) => {
                if (item.LooseSupplyItemItemID === id) {
                    item.Quantity = item.Quantity + mod
                    foundPart = true
                    // If the updated qty is 0, we need to later remove this item
                    if (item.Quantity <= 0) {
                        removeIndex = index
                    }

                    // If the updated qty exceeds the qty limit, set it to the limit
                    if (item.Quantity > partInfo.QtyLimit) {
                        item.Quantity = partInfo.QtyLimit
                    }
                }
            })
        }

        if (removeIndex !== -1) {
            updatedParts.splice(removeIndex, 1)
        }

        // If the part is not, and the mod is positive, then we need to add it in
        if (!foundPart && mod > 0) {
            updatedParts.push({
                LooseSupplyItemItemID: id,
                Quantity: 1
            })
        }

        this.setState({
            selectedParts: updatedParts
        })

        if (type === 'add') {
            analyticsDataLayer.addToCart({
                "currency": 'AUD',
                "value": partInfo.Price,
                "project_type": 'Commercial',
                "project_name": this.props.project.Title,
                "project_id": this.props.project.ItemID.toString(),
                "item_board_name": this.props.board.Name,
                "item_board_id": this.props.board.ItemID.toString(),
                "configuration_name": this.props.board.Name,
                "configuration_id": this.props.board.ItemID.toString(),         
                "items": [
                    {
                        "item_id": partInfo.BOMReference,
                        "item_name": partInfo.Description,
                        "item_brand": 'FlexSelect',
                        "item_category": partInfo.Category,
                        "item_variant": '',
                        "price": partInfo.Price,
                        "quantity": 1
                    }
                ]
            })
        } else {
            analyticsDataLayer.removeFromCart({
                "currency": 'AUD',
                "value": partInfo.Price,
                "project_type": 'Commercial',
                "project_name": this.props.project.Title,
                "project_id": this.props.project.ItemID.toString(),
                "item_board_name": this.props.board.Name,
                "item_board_id": this.props.board.ItemID.toString(),
                "configuration_name": this.props.board.Name,
                "configuration_id": this.props.board.ItemID.toString(), 
                "items": [
                    {
                        "item_id": partInfo.BOMReference,
                        "item_name": partInfo.Description,
                        "item_brand": 'FlexSelect',
                        "item_category": partInfo.Category,
                        "item_variant": '',
                        "price": partInfo.Price,
                        "quantity": 1
                    }
                ]
            })
        }
    }

    render() {
        return (
            <div className="flyout">
                <div className="flyout-container">
                    <div className="flyout__header">
                        <h3 className="flyout__title h3">Loose Items <span className="flyout__title-count">{this.getTotalSelectedQuantity()}</span></h3>
                        <button className="flyout__close button button--1" type="button" onClick={this.updateCall} aria-label="close flyout">
                            <span className="button__icon">
                                <span className="i-save"></span>
                            </span>
                            <span className="button__text">
                                Save &amp; close
                            </span>
                        </button>
                    </div>
                    <div className="flyout__body">
                        <p>Please note: Loose items will be delivered with the board, However they won’t come fitted/mounted to the board.</p>
                        <div className="loose-items">

                            {this.props.partsList.map(opt => {
                                const selectedQty = this.getPartSelectedQty(opt.ItemID)

                                const availabilityDataForSelectedBreaker = this.props.commercialAllPartsAvailable.find(item => item.ItemCode === opt.BOMReference)
                                let validationMessage = ''
                                if (!availabilityDataForSelectedBreaker?.IsInStock) {
                                    validationMessage = `${opt.Description} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`
                                } else if (availabilityDataForSelectedBreaker?.IsLowStock) {
                                    validationMessage = 'Low stock, longer lead times may apply'
                                    console.log('availabilityDataForSelectedBreaker', availabilityDataForSelectedBreaker)
                                } else {
                                    validationMessage = ''
                                }

                                return (
                                    <div>
                                        {
                                            (opt.BOMReference === 'LBASE2GALBK' || opt.BOMReference === 'LBASE3GALIP66') && selectedQty > 0 &&
                                            <p className="alert alert--warning" style={{ marginBottom: '1rem' }}>
                                                <span className="alert__icon">
                                                    <span className="i-exclamation"></span>
                                                </span>
                                                <span className="alert__text">
                                                    Bottom gland plate will be fitted inside the board and not removable
                                                </span>
                                            </p>
                                        }

                                        <div key={opt.ItemID} className={`loose-item ${selectedQty ? 'is-active' : ''}`} >
                                            <span className="loose-item__text">
                                                {opt.Description}
                                            </span>
                                            <div className="loose-item__actions">
                                                <button className="button button--add-remove remove" onClick={this.updateSelectedPart.bind(this, opt.ItemID, -1, 'remove')}>
                                                    <span className="button__icon">
                                                        <span className="i-remove"></span>
                                                    </span>
                                                </button>
                                                <span className="loose-item__count">
                                                    {selectedQty}
                                                </span>

                                                <button className="button button--add-remove add" disabled={selectedQty === opt.QtyLimit || !availabilityDataForSelectedBreaker?.IsInStock}
                                                    onClick={this.updateSelectedPart.bind(this, opt.ItemID, 1, 'add')}>
                                                    <span className="button__icon">
                                                        <span className="i-plus"></span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        {validationMessage &&
                                            <div className="modal__validation-msg">
                                                <span className="alert__icon">
                                                    <span className="i-exclamation"></span>
                                                </span>
                                                <span dangerouslySetInnerHTML={{ __html: validationMessage }}></span>
                                            </div>
                                        }
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
