exports.CONST = {
    API_PATH: '/Clipsal_LDB_API/api/v3',
    API_PATH_RESIDENTIAL: '/Clipsal_Residential_API/api/v3',
    LEGACY_API_PATH: '/api',
    API_PATH_SSO_LOCALHOST: 'https://localhost:7030',
    SSO_CURRENT_SITE: 'FLEXSELECT',
    SSO_API_KEY: 'dVCakwqW8Ri4R8kA1UBibJWGnvnvwROQ',
    RECAPTCH_SITE_KEY: '6Lc8Gl0qAAAAAPK6qBvbhChnogaIhXCTmWrulKG_',
    PAGES: {
        HOME: '/home',
        LOGIN: '/login',
        REGISTER: '/register',
        PROFILE: '/user-profile',
        UPDATE_PROFILE_INFO: '/Update-Profile-Info',
        PROJECT_DETAILS: '/project-details',
        PROJECT_DETAILS_RESIDENTIAL: '/project-details-residential',
        TECHNICAL_INFORMATION: '/technical-information',
        CONFIGURE_CHASSIS: '/config-chassis',
        CONFIGURE_CHASSIS_RESIDENTIAL: '/config-chassis-residential',
        QUOTE: '/project-quote',
        QUOTE_RESIDENTIAL: '/project-quote-residential'
    },
    MINIMUM_RESIDENTIAL_BOARD_QUANTITY: 30
}
  