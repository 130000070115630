export class AnalyticsDataLayer {
    createProject(project) {
        window.dataLayer.push({
            event: 'create_project',
            ...project
        })
    }

    deleteProject(project) {
        window.dataLayer.push({
            event: 'delete_project',
            ...project
        })
    }

    duplicateProject(project) {
        window.dataLayer.push({
            event: 'duplicate_project',
            ...project
        })
    }

    addBoard(board) {
        window.dataLayer.push({
            event: 'add_board',
            ...board
        })
    }

    addBreaker(breaker) {
        window.dataLayer.push({
            event: 'add_breaker',
            ...breaker
        })
    }

    addToFavourites(project) {
        window.dataLayer.push({
            event: 'add_to_wishlist',
            ecommerce: {
                ...project
            }
        })
    }

    removeFromFavourites(project) {
        window.dataLayer.push({
            event: 'remove_from_favorites',
            ...project

        })
    }

    viewItemList(project) {
        window.dataLayer.push({
            event: 'view_item_list',
            ecommerce: {
                ...project
            }
        })
    }

    addToCart(project, ctaName = 'quantity increase') {
        window.dataLayer.push({
            event: 'add_to_cart',
            cta_name: ctaName,
            ecommerce: {
                ...project
            }
        })
    }

    removeFromCart(project) {
        window.dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
                ...project
            }
        })
    }

    purchase(project) {
        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                ...project
            }
        })
    }

    ctaButtonClick(project) {
        window.dataLayer.push({
            event: 'cta_click',
            ...project
        })
    }

    viewQuote(project) {
        window.dataLayer.push({
            event: 'quote_creation',
            ...project
        })
    }

    emailQuote(project) {
        window.dataLayer.push({
            event: 'quote_interaction',
            ...project
        })
    }

    downloadLayout(project) {
        window.dataLayer.push({
            event: 'download',
            ...project
        })
    }

    registrationStep(funnelStep, funnelTotalSteps) {
        window.dataLayer.push({
            'event': 'registration_start',
            'funnel_step': funnelStep,
            'funnel_total_steps': funnelTotalSteps
        })
    }

    registrationComplete() {
        window.dataLayer.push({
            'event': 'registration_complete'
        })
    }

    login(loginDetails) {
        window.dataLayer.push({
            'event': 'login',
            ...loginDetails
        })
    }

    pageView(data) {
        window.dataLayer.push({
            'event': 'page_view',
            ...data
        })
    }
}

const analyticsDataLayer = new AnalyticsDataLayer()
export default analyticsDataLayer
