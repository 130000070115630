import React, { Component } from 'react'
import jQuery from 'jquery'
import cloneDeep from 'lodash.clonedeep'
import RadioObjectGroup from '../../../components/radio-object-group/RadioObjectGroup'
import GlobalOverlay from '../../../components/global-overlay/GlobalOverlay'
import { getNumberOfRowsFromPolesString, getNumberOfPolesFromStringExpression } from './../../../utils/helpers'
import BoardService from './../../../services/BoardService'
import { apiCallsResidential } from '../../../utils/apiCallsResidential'
import { canAddDeviceToBoardPosition } from './../../../utils/helpers'
import { orderBy } from 'lodash'
import analyticsDataLayer from '../../../services/AnalyticsDataLayer'
import BusbarService from '../../../services/BusbarService'

export default class BoardDetailsResidential extends Component {
    constructor(props) {
        super(props)

        // Internal states that no one else needs to know about holds info on the selection options
        this.initStates(this.props)
    }

    componentDidMount() {
        jQuery('body').addClass('modal-is-open')
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.board && !nextProps.board) {
            return
        }

        if (!this.props.board || !nextProps.board || this.props.board.ItemID !== nextProps.board.ItemID) {
            this.initStates(nextProps, true)
        }
    }

    initStates(propsData, useSetState = false) {
        // Default values
        let boardDetails = {
            Name: '',
            EnclosureType: '',
            PolesNeeded: '',
            MetalBlackPlate: null,
            IncomerPhase: '',
            MainSwitchType: '',
            CircuitBreaker: '',
            MainSwitchRating: '',
            SurgeProtection: '',
            MaxDischargeCapacity: ''
        }

        if (propsData.board) {
            const updatedBoardDetails = BoardService.getDetails(this.props.boardOptionData,
                propsData.board.EnclosureID, propsData.board.MainSwitchID, propsData.board.SPDID, propsData.board.MetalBlackPlateID)

            boardDetails = {
                Name: propsData.board.Name,
                MainSwitchPosition: propsData.board.MainSwitchPosition,
                ...updatedBoardDetails
            }
        }

        const updatedState = {
            boardDetails: boardDetails,
            board: propsData.board ? propsData.board : null,
            isExistingBoard: propsData.board ? true : false,
            currentStep: 1, // Used to indicate the step the design is divided
            currentStepStatus: {
                1: false,
                2: false,
                3: false
            },
            canGoNextStep: false,
            hasUsedBackButton: false,
            isLoading: false,
            validationMessage: '',
            outOfStock: false
        }

        // If it is an existing board, then we allow the user to skip between the step as if he used a back button
        // Also remember if it had a Surge Protection with a mandatory MCB, so when he decides to remove the Surge
        // Protection we will also remove the mandatory MCB it comes attached with
        if (updatedState.isExistingBoard) {
            updatedState.hasUsedBackButton = true
            updatedState.hasSurgeProtectionMandatoryMCB = false

            const SPDDetails = this.getSPDDetails(boardDetails.MaxDischargeCapacity, boardDetails.IncomerPhase)
            if (SPDDetails !== null && BoardService.getSurgeProtectionRequiredMCBReference(boardDetails.IncomerPhase, boardDetails.MaxDischargeCapacity) !== null) {
                updatedState.hasSurgeProtectionMandatoryMCB = true
                updatedState.existingSPDDetails = SPDDetails
            }
        }

        if (useSetState) {
            this.setState(updatedState)
        } else {
            this.state = updatedState
        }
    }

    // Update the selected option value in local state
    optionSelectedCall(value, key) {
        // If user selects anything reset availability message check as it has probably changed
        this.setState({
            validationMessage: '',
            outOfStock: false
        })

        const updatedBoard = cloneDeep(this.state.boardDetails)
        updatedBoard[key] = value

        if (key === 'EnclosureType' && value === 'Surface Mount') {
            updatedBoard.MetalBlackPlate = null
        }

        if (key === 'IncomerPhase' || key === 'MainSwitchType') {
            updatedBoard.MainSwitchRating = ''
            updatedBoard.CircuitBreaker = ''
        }

        if (key === 'SurgeProtection' && value === false) {
            updatedBoard.MaxDischargeCapacity = ''
        }

        // In this unique case, user can't have a surge protection, so we select it as No and disable Yes option
        if (updatedBoard.IncomerPhase === '2 Phase') {
            updatedBoard.SurgeProtection = false
            updatedBoard.MaxDischargeCapacity = null
        }

        this.setState({ boardDetails: updatedBoard }, () => {
            // Check if we need to reset the value for the metal black plate
            const metalBlackPlateEnabledOptions = this.getMetalBlackPlateEnabledOptions()
            if (metalBlackPlateEnabledOptions.indexOf(updatedBoard.MetalBlackPlate) === -1) {
                updatedBoard.MetalBlackPlate = null
            }

            // Check if we need to reset the value for the metal black plate
            const surgeProtectionEnabledOptions = this.getSurgeProtectionEnabledOptions()
            if (surgeProtectionEnabledOptions.indexOf(updatedBoard.SurgeProtection) === -1) {
                updatedBoard.SurgeProtection = null
                updatedBoard.MaxDischargeCapacity = null
            }

            this.setState({ boardDetails: updatedBoard })

            if (this.state.currentStep === 1 && this.canGoNextStep()) {

            }

            // If the user has used the back button, the don't automatically skip the steps, we will show 
            if (this.canGoNextStep()) {
                this.setState({ canGoNextStep: true })
            } else {
                this.setState({ canGoNextStep: false })
            }
        })
    }

    getSPDDetails(maxDischargeCapacity, incomerPhase) {
        let SPDDetails = null
        this.props.boardOptionData.SDPs.forEach(boardOption => {
            if (maxDischargeCapacity !== '') {
                if (boardOption.MaxDischargeCapacity === maxDischargeCapacity &&
                    boardOption.IncomerPhase === incomerPhase) {
                    SPDDetails = boardOption
                }
            }
        })

        return SPDDetails
    }

    saveBoard() {
        analyticsDataLayer.ctaButtonClick({
            cta_name: 'Add Board',
            cta_link: window.location.href,
            cta_location: 'board details page'
        })

        const finalSelections = cloneDeep(this.state.boardDetails)

        console.info('finalSelections', this.props.boardOptionData)

        // Get enclousure reference
        let enclousureReference = null
        this.props.boardOptionData.Enclosures.forEach(boardOption => {
            if (boardOption.EnclosureType === finalSelections.EnclosureType && boardOption.PolesNeeded === finalSelections.PolesNeeded) {
                enclousureReference = boardOption.ItemID
            }
        })

        if (enclousureReference === null) {
            window.alert('Could not find Enclosure reference, please assure all options were selected.')
            return
        }

        let metalBlackPlateID = null
        if (finalSelections.MetalBlackPlate === true) {
            this.props.boardOptionData.Enclosures.forEach(boardOption => {
                const currentSelectionRows = getNumberOfRowsFromPolesString(finalSelections.PolesNeeded)
                if (boardOption.EnclosureType === 'Flush Mount Enclosure - Metal back plate' && boardOption.NumberOfRows === currentSelectionRows) {
                    metalBlackPlateID = boardOption.ItemID
                }
            })

            if (metalBlackPlateID === null) {
                window.alert('Could not find reference number for the selected metal black plate, please assure all options were selected.')
                return
            }
        }

        // Get main Switch reference
        let mainSwitchReference = null
        for (let index = 0; index < this.props.boardOptionData.MainSwitches.length; index++) {
            const boardOption = this.props.boardOptionData.MainSwitches[index];
            if (boardOption.CurrentRating === finalSelections.MainSwitchRating &&
                boardOption.IncomerPhase === finalSelections.IncomerPhase &&
                boardOption.SwitchType === finalSelections.MainSwitchType) {
                if (!!finalSelections.CircuitBreaker) {
                    if (boardOption.Rating === finalSelections.CircuitBreaker) {
                        mainSwitchReference = boardOption.ItemID
                        break
                    }
                } else {
                    mainSwitchReference = boardOption.ItemID
                    break
                }
            }
        }

        if (mainSwitchReference === null) {
            window.alert('Could not find Main Switch reference, please assure all options were selected.')
            return
        }

        const SPDDetails = this.getSPDDetails(finalSelections.MaxDischargeCapacity, finalSelections.IncomerPhase)

        // Determinate main switch Position
        let mainSwitchPosition = 0
        if (SPDDetails !== null) {
            mainSwitchPosition = SPDDetails.SpaceUsed
        }

        // When editing the board remember previous main switch position
        if (this.props.board !== null) {
            mainSwitchPosition = parseInt(this.props.board.MainSwitchPosition, 10)

            // But assure it's not conflicting with Surge Protection if that was added
            if (SPDDetails !== null && mainSwitchPosition === 0) {
                // This potentially can cause some problems if the user edits the board and adds a surge protection
                // on a board where there was already a device on the right side of the main switch  
                mainSwitchPosition = SPDDetails.SpaceUsed
            }

            // And if the user has decided to not have a Surge Protection and had a mandatory MCB attached to it,
            // then we go and remove that breaker
            if ((SPDDetails === null && this.state.hasSurgeProtectionMandatoryMCB) ||
                // If the user decided to have a different main incomer, but still have a surge protection, then still delete the mandatory MCB as
                // further checks will recognise it's not there and add a new one
                (SPDDetails !== null && this.state.existingSPDDetails !== undefined && this.state.existingSPDDetails.ItemID !== SPDDetails.ItemID)) {
                // Since Surge Protection is always the first element on the first row and the mandatory MCB
                // is always attached to it on the right, then we know it's the first breaker in the collection with the lowest index
                // Yet we still double check it by reference code just in case the MCB wasn't added in case of any issues
                const orderedBreakers = orderBy(this.state.board.ProjectBoardBreakerCollection, ['RowIndex', 'PoleIndex'])
                const requiredMCBReference = BoardService.getSurgeProtectionRequiredMCBReference(this.state.existingSPDDetails.IncomerPhase, this.state.existingSPDDetails.MaxDischargeCapacity)

                if (orderedBreakers.length > 0 && orderedBreakers[0].BreakerTypeReadOnly.Reference === requiredMCBReference) {
                    this.props.deleteBreakerFromBoard(orderedBreakers[0].RowIndex, orderedBreakers[0].PoleIndex, orderedBreakers[0].ItemID, this.props.board.ItemID)
                }
            }

            // Make sure the main switch won't go over any existing breakers or devices, it can happen if we have a phase main incomer
            // and a breaker on the right side of the main switch and the user decides to change the size of the main incomer
            const canChangeMainSwitchSize = canAddDeviceToBoardPosition(BoardService.getMainSwitchIncomingPhasesPoles(finalSelections.IncomerPhase), 0, mainSwitchPosition, false, this.state.board, this.state.boardDetails, this.props.boardOptionData, true, null, null, null, false, true)

            if (!canChangeMainSwitchSize) {
                // Get current main switch by MainSwitchID
                const currentMainSwitch = this.props.boardOptionData.MainSwitches.find(mainSwitch => mainSwitch.ItemID === this.props.board.MainSwitchID)
                const spacesToMove = BoardService.getMainSwitchIncomingPhasesPoles(finalSelections.IncomerPhase) - BoardService.getMainSwitchIncomingPhasesPoles(currentMainSwitch.IncomerPhase)
                window.alert(`Could not change the size of the main incomer, it would overlap an existing device, please adjust your board and assure there is the correct amount of space available. You will need to free up ${spacesToMove} space${spacesToMove > 1 ? 's' : ''} on the right side of the main incomer.`)
                return
            }

            // Also we need to put a check for the Neutra Terminal Block, if it's present, then we need to ask user to make adjustments
            if (SPDDetails !== null) {
                const busbarsWithNeutralBlock = BusbarService.getBusbarsWithNeutralTerminalBlockReferences()
                const firstRowBusbar = this.state.board.ProjectBoardBusbarCollection.find(busbar => busbar.Row === 1 && busbar.BusBarReadOnly.MountingPosition === 'Top')

                if (firstRowBusbar !== undefined && busbarsWithNeutralBlock.includes(firstRowBusbar.BusBarReadOnly.Reference)) {
                    window.alert('The board contains a Neutral Terminal Block in the position of the Surge Protection. Please make necessary adjustments to the board to assure the Surge Protection can be added.')
                    return
                }
            }
        }

        // If the main switch is already on position N (just after the surge protection), but an MCB is required on the right, the move
        // the main switch by N positions (depending on the size of the surge protection and MCB)
        if (SPDDetails !== null && mainSwitchPosition === SPDDetails.SpaceUsed && BoardService.getSurgeProtectionRequiredMCBReference(finalSelections.IncomerPhase, finalSelections.MaxDischargeCapacity) !== null) {
            // We also need to consider if that mandatory MCB is just 1 or 3 poles, based on the main incomer phases
            if (finalSelections.IncomerPhase === 'Single Phase') {
                mainSwitchPosition = SPDDetails.SpaceUsed + 1
            } else if (finalSelections.IncomerPhase === '3 Phase') {
                mainSwitchPosition = SPDDetails.SpaceUsed + 3
            }
        }

        // Create the final board object to be passed to the server
        let finalBoard = {
            Name: finalSelections.Name, // or boardName?
            ProjectID: this.props.projectID, // Current Project ID to make association
            EnclosureID: enclousureReference,
            MainSwitchID: mainSwitchReference,
            SPDID: SPDDetails !== null ? SPDDetails.ItemID : null,
            MetalBlackPlateID: metalBlackPlateID, // Metal Black plate can be null if it wasn't selected as an option
            Quantity: 30, // Default quantity
            MainSwitchPosition: mainSwitchPosition
        }

        if (this.state.isExistingBoard) {
            finalBoard.ItemID = this.state.board.ItemID
            finalBoard.HasWiserEnergyGateway = this.props.board.HasWiserEnergyGateway
            finalBoard.WiserEnergyGatewayPosition = this.props.board.WiserEnergyGatewayPosition
            finalBoard.WiserEnergyGatewayRow = this.props.board.WiserEnergyGatewayRow
        }

        this.setState({ isLoading: true })

        apiCallsResidential.apiSaveBoard(finalBoard)
            .then(boardData => {
                this.setState({ board: boardData }, () => {
                    // If a surge protection is required and it's a new board, then add the mandatory MCB breaker
                    const requiredSurgeProtectionMCBReference = BoardService.getSurgeProtectionRequiredMCBReference(finalSelections.IncomerPhase, finalSelections.MaxDischargeCapacity)
                    console.log('requiredSurgeProtectionMCBReference', requiredSurgeProtectionMCBReference)

                    if (requiredSurgeProtectionMCBReference !== null) {
                        console.log('This board requires a mandatory MCB: ' + requiredSurgeProtectionMCBReference + ', checking if it is added on the right side of the Surge Protection...')

                        const isCorrectMCBPresentAtRequiredPosition = this.state.board.ProjectBoardBreakerCollection.some(boardBreaker => {
                            return boardBreaker.RowIndex === 0 && boardBreaker.PoleIndex === SPDDetails.SpaceUsed && boardBreaker.BreakerTypeReadOnly.Reference === requiredSurgeProtectionMCBReference
                        })

                        // Probably we can refactor to use canAddDeviceToPosition function to better check if the MCB can be added here  
                        let isDifferentDevicePresentAtRequiredPosition = false
                        let isCorrectDevicePresentAtRequiredPosition = false
                        let canAddDeviceToPosition = false
                        if (mainSwitchPosition === SPDDetails.SpaceUsed) {
                            isDifferentDevicePresentAtRequiredPosition = true
                        } else {
                            isDifferentDevicePresentAtRequiredPosition = this.state.board.ProjectBoardBreakerCollection.some(boardBreaker => {
                                return boardBreaker.RowIndex === 0 && boardBreaker.PoleIndex === SPDDetails.SpaceUsed && boardBreaker.BreakerTypeReadOnly.Reference !== requiredSurgeProtectionMCBReference
                            })

                            isCorrectDevicePresentAtRequiredPosition = this.state.board.ProjectBoardBreakerCollection.some(boardBreaker => {
                                return boardBreaker.RowIndex === 0 && boardBreaker.PoleIndex === SPDDetails.SpaceUsed && boardBreaker.BreakerTypeReadOnly.Reference === requiredSurgeProtectionMCBReference
                            })

                            if (!isDifferentDevicePresentAtRequiredPosition && !isCorrectDevicePresentAtRequiredPosition) {
                                canAddDeviceToPosition = canAddDeviceToBoardPosition(SPDDetails.SpaceUsed, 0, SPDDetails.SpaceUsed, false, this.state.board, this.state.boardDetails, this.props.boardOptionData, false, null, null, null, false, true)
                                console.log('canAddDeviceToPosition', canAddDeviceToPosition)
                            }
                        }

                        console.log('isCorrectMCBPresentAtRequiredPosition', isCorrectMCBPresentAtRequiredPosition)
                        console.log('isDifferentDevicePresentAtRequiredPosition', isDifferentDevicePresentAtRequiredPosition)

                        if (!isCorrectMCBPresentAtRequiredPosition) {
                            if (isDifferentDevicePresentAtRequiredPosition) {
                                // This is probably the case when the mandatory MCB was removed and another breaker was added or
                                // The board was edited to add surge protection and another breaker was already there
                                // This could be handled in many different ways, will this occur often? It's hard to say, so as for now let's handle
                                // it with a nice alert, not our error message for now due to importance of the issue and action required from the user 
                                window.alert('The board requires a mandatory MCB with reference ' + requiredSurgeProtectionMCBReference + ' on the right side of the Surge protection, but another device was found. Please remove existing device on the right side of the Surge protection and save the board again to fix this issue.')

                                this.setState({ isLoading: false, isExistingBoard: true })
                                this.closeModal()
                            } else {
                                // Here we know that we need a specific MCB on the right of the surge protection and that the space is empty
                                // So let's add that breaker to that specific position

                                if (!canAddDeviceToPosition) {
                                    window.alert('The board requires a mandatory MCB with reference ' + requiredSurgeProtectionMCBReference + ' on the right side of the Surge protection, but not enough space was found to place it. Please remove or move any existing devices on the right side of the Surge protection and save the board again to fix this issue.')

                                    this.setState({ isLoading: false, isExistingBoard: true })
                                    this.closeModal()
                                } else {
                                    // 1. Find breaker by it's reference
                                    const foundBreakerData = this.props.breakerOptionData.find(breakerOption => breakerOption.Reference === requiredSurgeProtectionMCBReference)
                                    console.log('We found the data of the breaker to add', foundBreakerData)

                                    // 2. Save breaker directly to the board, as for not don't add it to the library
                                    apiCallsResidential.apiSaveBreakerToChassis(this.props.projectID, this.state.board.ItemID, 0, foundBreakerData.ItemID, 0, SPDDetails.SpaceUsed)
                                        .then(() => {
                                            this.setState({ isLoading: false, isExistingBoard: true })
                                            this.closeModal()
                                        })
                                        .catch(error => {
                                            this.setState({ isLoading: false })
                                            window.alert(`There was an error saving the board: ${error.message}`)
                                        })
                                }
                            }
                        } else {
                            this.setState({ isLoading: false, isExistingBoard: true })
                            this.closeModal()
                        }
                    } else {
                        this.setState({ isLoading: false, isExistingBoard: true })
                        this.closeModal()
                    }

                    if (!this.state.isExistingBoard) {
                        analyticsDataLayer.addBoard({
                            "item_board_name": boardData.Name,
                            "item_board_id": boardData.ItemID.toString(),
                            "configuration_name": boardData.Name,
                            "configuration_id": boardData.ItemID.toString(), 
                            "project_name": this.props.projectName,
                            "project_id": this.props.projectID.toString(),
                            "project_type": 'Residential',
                            "funnel_step": '3',
                            "funnel_total_steps": '3'
                        })
                    }
                })
            })
            .catch(error => {
                this.setState({ isLoading: false })
                window.alert(`There was an error saving the board: ${error.message}`)
            })
    }

    checkIfInStock = (availabilityItem) => {
        if (!availabilityItem?.IsInStock) {
            this.setState({
                validationMessage: `${availabilityItem.Description} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`,
                outOfStock: true
            })
            return false
        } else if (availabilityItem?.IsLowStock) {
            this.setState({
                validationMessage: `Low stock, longer lead times may apply`,
                outOfStock: false
            })
            return true
        } else {
            this.setState({
                validationMessage: '',
                outOfStock: false
            })
            return true
        }
    }

    // Handle switching of the steps in the UI, this is a very specific manual division
    canGoNextStep = () => {
        let canGoNextStep = false

        if (this.state.currentStep === 1) {
            if ((this.state.boardDetails.EnclosureType === 'Surface Mount' && this.state.boardDetails.PolesNeeded !== '') ||
                (this.state.boardDetails.EnclosureType === 'Flush Mount' && this.state.boardDetails.PolesNeeded !== '' && this.state.boardDetails.MetalBlackPlate !== null)
            ) {
                let isFormValid = true
                let enclousureReference = null
                this.props.boardOptionData.Enclosures.forEach(boardOption => {
                    if (boardOption.EnclosureType === this.state.boardDetails.EnclosureType && boardOption.PolesNeeded === this.state.boardDetails.PolesNeeded) {
                        console.log('enclousureReference boardOption', boardOption)
                        enclousureReference = boardOption.Reference
                    }
                })
                let availabilityEnclousureReference = this.props.residentialAllPartsAvailable.find(part => part.ItemCode === enclousureReference)
                isFormValid = this.checkIfInStock(availabilityEnclousureReference)

                let metalBlackPlateID = null
                if (this.state.boardDetails.MetalBlackPlate === true) {
                    this.props.boardOptionData.Enclosures.forEach(boardOption => {
                        const currentSelectionRows = getNumberOfRowsFromPolesString(this.state.boardDetails.PolesNeeded)
                        if (boardOption.EnclosureType === 'Flush Mount Enclosure - Metal back plate' && boardOption.NumberOfRows === currentSelectionRows) {
                            console.log('Black metal boardOption', boardOption)
                            metalBlackPlateID = boardOption.Reference
                        }
                    })
                    if (metalBlackPlateID === null) {
                        window.alert('Could not find reference number for the selected metal black plate, please assure all options were selected.')
                        return
                    }
                }

                if (metalBlackPlateID) {
                    let availabilityMetalBlackPlate = this.props.residentialAllPartsAvailable.find(part => part.ItemCode === metalBlackPlateID)
                    isFormValid = this.checkIfInStock(availabilityMetalBlackPlate)
                }

                canGoNextStep = isFormValid
            }
        } else if (this.state.currentStep === 2) {
            if (this.state.boardDetails.MainSwitchRating !== '') {

                // Get main Switch reference
                let mainSwitchReference = null
                for (let index = 0; index < this.props.boardOptionData.MainSwitches.length; index++) {
                    const boardOption = this.props.boardOptionData.MainSwitches[index];
                    if (boardOption.CurrentRating === this.state.boardDetails.MainSwitchRating &&
                        boardOption.IncomerPhase === this.state.boardDetails.IncomerPhase &&
                        boardOption.SwitchType === this.state.boardDetails.MainSwitchType) {
                        if (!!this.state.boardDetails.CircuitBreaker) {
                            if (boardOption.Rating === this.state.boardDetails.CircuitBreaker) {
                                mainSwitchReference = boardOption.Reference
                                break
                            }
                        } else {
                            mainSwitchReference = boardOption.Reference
                            break
                        }
                    }
                }
                let availability = this.props.residentialAllPartsAvailable.find(part => part.ItemCode === mainSwitchReference)
                let isFormValid = this.checkIfInStock(availability)

                canGoNextStep = isFormValid
            }
        } else if (this.state.currentStep === 3) {
            if (this.state.boardDetails.SurgeProtection === false ||
                (this.state.boardDetails.SurgeProtection === true && this.state.boardDetails.MaxDischargeCapacity !== '') ||
                (this.state.boardDetails.SurgeProtection === true && this.state.boardDetails.IncomerPhase === '2 Phase')) {

                const SPDDetails = this.getSPDDetails(this.state.boardDetails.MaxDischargeCapacity, this.state.boardDetails.IncomerPhase)
                let isFormValid = true
                if (SPDDetails?.Reference) {
                    let availability = this.props.residentialAllPartsAvailable.find(part => part.ItemCode === SPDDetails?.Reference)
                    if (availability) {
                        isFormValid = this.checkIfInStock(availability)
                    }
                }

                canGoNextStep = isFormValid
            }
        }

        return canGoNextStep
    }

    renderModalHeader = () => {
        let progress = this.state.currentStep * 33.33
        if (this.state.isExistingBoard) {
            progress = 100
        }

        const isNotAllowedToGoStep2 = (this.state.currentStep === 1 && !this.state.isExistingBoard && !this.state.canGoNextStep)
        const isNotAllowedToGoStep3 = (this.state.currentStep === 2 && !this.state.canGoNextStep) || (this.state.currentStep < 2 && !this.state.isExistingBoard)

        return (
            <div className="modal__header">
                <button className="modal__close button button--icon" type="button" aria-label="close modal" onClick={this.closeModal}>
                    <span className="i-close"></span>
                </button>
                <h4 className="modal__title h3">
                    {this.state.isExistingBoard && <span>Edit board</span>}
                    {!this.state.isExistingBoard && <span>Add a board</span>}
                </h4>
                <div className="steps">
                    <div className={'step' + (this.state.currentStep === 1 ? ' is-active' : ' is-done')} onClick={() => { this.goStep(1) }}>
                        <span className="step__number">1</span>
                        <span className="step__text">Set-Up</span>
                    </div>
                    <div className={'step' +
                        (this.state.currentStep === 2 ? ' is-active' : '') +
                        (this.state.currentStep > 2 || this.state.isExistingBoard ? ' is-done' : '') +
                        (isNotAllowedToGoStep2 ? ' not-allowed' : '')
                    } onClick={() => { if (!isNotAllowedToGoStep2) this.goStep(2) }}>
                        <span className="step__number">2</span>
                        <span className="step__text">Main Incomer</span>
                    </div>
                    <div className={'step' +
                        (this.state.currentStep === 3 ? ' is-active' : '') +
                        (this.state.currentStep > 3 || this.state.isExistingBoard ? ' is-done' : '') +
                        (isNotAllowedToGoStep3 ? ' not-allowed' : '')
                    } onClick={() => { if (!isNotAllowedToGoStep3) this.goStep(3) }}>
                        <span className="step__number">3</span>
                        <span className="step__text">Extras</span>
                    </div>
                </div>
                <div className="steps-progress">
                    <div className="steps-progress-bar" style={{ width: progress + '%' }}></div>
                </div>
            </div>
        )
    }

    renderModalFooter = () => {
        return (
            <div className="modal__footer">
                {
                    this.state.currentStep > 1 &&
                    <button className="button button--back" onClick={() => { this.goStepBack() }}>
                        <span className="button__text">
                            Previous
                        </span>
                    </button>
                }

                {
                    (!this.state.isExistingBoard && this.state.currentStep === 1) &&
                    <button className="button button--1" disabled={!this.state.canGoNextStep} onClick={() => { this.goNextStep() }}>
                        <span className="button__text">
                            Confirm Selection
                        </span>
                    </button>
                }

                {
                    !this.state.isExistingBoard && this.state.currentStep !== 3 && this.state.currentStep !== 1 &&
                    <button className="button button--1" disabled={!this.state.canGoNextStep} onClick={() => { this.goNextStep() }}>
                        <span className="button__text">
                            Next
                        </span>
                    </button>
                }

                {
                    this.state.isExistingBoard && this.state.currentStep !== 3 &&
                    <button className="button button--forward" onClick={() => { this.goNextStep() }}>
                        <span className="button__text">
                            Next
                        </span>
                    </button>
                }

                {
                    this.state.isExistingBoard && this.state.currentStep < 3 &&
                    <button className="button button--1" onClick={() => { this.saveBoard() }}>
                        <span className="button__text">
                            Save &amp; Close
                        </span>
                    </button>
                }

                {/* This is only showing on last step */}
                {
                    this.state.currentStep === 3 &&
                    <button className="button button--1" disabled={!this.state.canGoNextStep} onClick={() => { this.saveBoard() }}>
                        <span className="button__text">
                            {this.state.isExistingBoard && <span>Update board</span>}
                            {!this.state.isExistingBoard && <span>Add board</span>}
                        </span>
                    </button>
                }
            </div>
        )
    }

    goStepBack = () => {
        this.setState({
            currentStep: this.state.currentStep - 1,
            hasUsedBackButton: true
        }, () => this.setState({ canGoNextStep: this.canGoNextStep() }))
    }

    goNextStep = () => {
        this.setState({
            currentStep: this.state.currentStep + 1,
            validationMessage: ''
        }, () => this.setState({ canGoNextStep: this.canGoNextStep() }))

        if (!this.state.currentStepStatus[this.state.currentStep]) {
            analyticsDataLayer.addBoard({
                "item_board_name": '',
                "item_board_id": '',
                "configuration_name": '',
                "configuration_id": '', 
                "project_name": this.props.projectName,
                "project_id": this.props.projectID.toString(),
                "project_type": 'Residential',
                "funnel_step": this.state.currentStep,
                "funnel_total_steps": '3'
            })
        }

        this.setState({
            currentStepStatus: {
                ...this.state.currentStepStatus,
                [this.state.currentStep]: true
            }
        })
    }

    goStep = (stepNumber) => {
        if (this.state.isExistingBoard || (!this.state.isExistingBoard && stepNumber !== 3)) {
            this.setState({
                currentStep: stepNumber
            }, () => this.setState({ canGoNextStep: this.canGoNextStep() }))
        }
    }

    closeModal = () => {
        jQuery('body').removeClass('modal-is-open')

        if (this.props.closeCallback !== undefined) {
            this.props.closeCallback(this.state.board)
        }
    }

    getCircuitBreakerEnabledOptions = () => {
        if (this.state.boardDetails.IncomerPhase === 'Single Phase') {
            return ['4.5kA', '6kA']
        } else {
            return ['6kA']
        }
    }

    getMetalBlackPlateEnabledOptions = () => {
        const polesSelected = getNumberOfPolesFromStringExpression(this.state.boardDetails.PolesNeeded)
        if (polesSelected === 15) {
            return [true, false]
        } else {
            return [false]
        }
    }

    getMainSwitchRatingEnabledOptions = () => {
        if (this.state.boardDetails.MainSwitchType === 'Circuit breaker') {
            return ['40A', '50A', '63A']
        } else if (this.state.boardDetails.MainSwitchType === 'Switch Isolator') {
            if (this.state.boardDetails.IncomerPhase === 'Single Phase') {
                return ['80A', '100A']
            } else {
                return ['100A']
            }
        }
    }

    getSurgeProtectionEnabledOptions = () => {
        if (this.state.boardDetails.IncomerPhase === '2 Phase') {
            // You can't select a surge protection for 2 Phases
            return [false]
        } else {
            return [true, false]
        }
    }

    getMaxDischargeCapacityEnabledOptions = () => {
        if (this.state.boardDetails.IncomerPhase === 'Single Phase') {
            return ['20kA', '40kA']
        } else if (this.state.boardDetails.IncomerPhase === '3 Phase') {
            return ['40kA']
        } else {
            return []
        }
    }

    render() {
        const boardOptions = this.props.boardOptions
        const boardDetails = this.state.boardDetails
        console.warn(boardDetails)
        const polesNeededEnabled = boardDetails.EnclosureType !== ''
        const polesNeededSelected = boardDetails.PolesNeeded !== ''

        return (
            <div id="add-board" className="modal modal--steps add-board-modal">
                {
                    this.state.isLoading &&
                    <GlobalOverlay>
                        <div>
                            <div className="globalOverlay__message">
                                <span className="globalOverlay__message-spinner">
                                    <span className="spinner"></span>
                                </span>
                                {
                                    this.state.isExistingBoard ?
                                        <span className="h3">Updating</span>
                                        :
                                        <span className="h3">
                                            {this.state.currentStep === 5 ? 'Adding board' : 'Saving'}
                                        </span>
                                }
                            </div>
                        </div>
                    </GlobalOverlay>
                }

                <div className="modal-container">
                    {this.renderModalHeader()}

                    <div className="modal__body">
                        {/* Step 1 */}
                        <form className={'form' + (this.state.currentStep === 1 ? ' form-show' : ' form-hide')}>
                            <div className="row">
                                <div className="col-6">
                                    <div className={'form__group form__group--step is-active' +
                                        (this.state.boardDetails.Name !== null && this.state.boardDetails.Name !== '' ? ' is-done' : '')
                                    }>
                                        <label className="form-label-step" htmlFor="board-create-name">
                                            <div className="form-label-step__icon">
                                                <span className="i-check"></span>
                                            </div>
                                            <span className="form-label-step__text">
                                                Board Name
                                            </span>
                                        </label>
                                        <input id="board-create-name" className="form__input input" type="text" name="nameNewProject" autoFocus
                                            onChange={event => {
                                                this.optionSelectedCall(event.target.value, 'Name')
                                            }}
                                            maxLength="128"
                                            placeholder="Type the board name"
                                            value={boardDetails.Name}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Enclosure Type"
                                        enabled={boardDetails.Name !== ''}
                                        options={boardOptions.EnclosureType}
                                        selectedValue={boardDetails.EnclosureType}
                                        selecteCallback={value => {
                                            this.optionSelectedCall(value, 'EnclosureType')
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">

                                    {/* This is a very unique design, so we are not making it as a reusable component */}
                                    <div className={`form__group form__group--step radio-button-row radio-button-row--min-width
                                        ${polesNeededEnabled ? ' is-active' : ''} ${polesNeededSelected ? ' is-done' : ''}`}>
                                        <h4 className="form-label-step">
                                            <div className="form-label-step__icon">
                                                <span className="i-check"></span>
                                            </div>
                                            <span className="form-label-step__text">Poles Needed</span>
                                        </h4>
                                        <div className="radio-row">
                                            <p className="radio-row__title">
                                                1 row
                                            </p>
                                            <div className="radio-list">
                                                <div className={`form__control radio radio--button ${polesNeededSelected ? ' is-selected' : ''} ${!polesNeededEnabled ? ' is-disabled' : ''}`}>
                                                    <input id="poles-needed-0" type="radio" name="Poles Needed" className="form__control-input"
                                                        onChange={() => { this.optionSelectedCall('8', 'PolesNeeded') }} checked={boardDetails.PolesNeeded === '8'} />
                                                    <label htmlFor="poles-needed-0" className="form__control-label button button--3">8</label>
                                                </div>
                                                <div className={`form__control radio radio--button ${polesNeededSelected ? ' is-selected' : ''} ${!polesNeededEnabled ? ' is-disabled' : ''}`}>
                                                    <input id="poles-needed-1" type="radio" name="Poles Needed" className="form__control-input"
                                                        onChange={() => { this.optionSelectedCall('11', 'PolesNeeded') }} checked={boardDetails.PolesNeeded === '11'} />
                                                    <label htmlFor="poles-needed-1" className="form__control-label button button--3">11</label>
                                                </div>
                                                <div className={`form__control radio radio--button ${polesNeededSelected ? ' is-selected' : ''} ${!polesNeededEnabled ? ' is-disabled' : ''}`}>
                                                    <input id="poles-needed-2" type="radio" name="Poles Needed" className="form__control-input"
                                                        onChange={() => { this.optionSelectedCall('12', 'PolesNeeded') }} checked={boardDetails.PolesNeeded === '12'} />
                                                    <label htmlFor="poles-needed-2" className="form__control-label button button--3">12</label>
                                                </div>
                                                <div className={`form__control radio radio--button ${polesNeededSelected ? ' is-selected' : ''} ${!polesNeededEnabled ? ' is-disabled' : ''}`}>
                                                    <input id="poles-needed-3" type="radio" name="Poles Needed" className="form__control-input"
                                                        onChange={() => { this.optionSelectedCall('15', 'PolesNeeded') }} checked={boardDetails.PolesNeeded === '15'} />
                                                    <label htmlFor="poles-needed-3" className="form__control-label button button--3">15</label>
                                                </div>
                                                <div className={`form__control radio radio--button ${polesNeededSelected ? ' is-selected' : ''} ${!polesNeededEnabled ? ' is-disabled' : ''}`}>
                                                    <input id="poles-needed-4" type="radio" name="Poles Needed" className="form__control-input"
                                                        onChange={() => { this.optionSelectedCall('17', 'PolesNeeded') }} checked={boardDetails.PolesNeeded === '17'} />
                                                    <label htmlFor="poles-needed-4" className="form__control-label button button--3">17</label>
                                                </div>
                                                <div className={`form__control radio radio--button ${polesNeededSelected ? ' is-selected' : ''} ${!polesNeededEnabled ? ' is-disabled' : ''}`}>
                                                    <input id="poles-needed-5" type="radio" name="Poles Needed" className="form__control-input"
                                                        onChange={() => { this.optionSelectedCall('18', 'PolesNeeded') }} checked={boardDetails.PolesNeeded === '18'} />
                                                    <label htmlFor="poles-needed-5" className="form__control-label button button--3">18</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="radio-row">
                                            <p className="radio-row__title">
                                                2 rows
                                            </p>
                                            <div className="radio-list">
                                                <div className={`form__control radio radio--button ${polesNeededSelected ? ' is-selected' : ''} ${!polesNeededEnabled ? ' is-disabled' : ''}`}>
                                                    <input id="poles-needed-6" type="radio" name="Poles Needed" className="form__control-input"
                                                        onChange={() => { this.optionSelectedCall('12x2', 'PolesNeeded') }} checked={boardDetails.PolesNeeded === '12x2'} />
                                                    <label htmlFor="poles-needed-6" className="form__control-label button button--3">12x2</label>
                                                </div>
                                                <div className={`form__control radio radio--button ${polesNeededSelected ? ' is-selected' : ''} ${!polesNeededEnabled ? ' is-disabled' : ''}`}>
                                                    <input id="poles-needed-7" type="radio" name="Poles Needed" className="form__control-input"
                                                        onChange={() => { this.optionSelectedCall('15x2', 'PolesNeeded') }} checked={boardDetails.PolesNeeded === '15x2'} />
                                                    <label htmlFor="poles-needed-7" className="form__control-label button button--3">15x2</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="radio-row">
                                            <p className="radio-row__title">
                                                3 rows
                                            </p>
                                            <div className="radio-list">
                                                <div className={`form__control radio radio--button ${polesNeededSelected ? ' is-selected' : ''} ${!polesNeededEnabled ? ' is-disabled' : ''}`}>
                                                    <input id="poles-needed-8" type="radio" name="Poles Needed" className="form__control-input"
                                                        onChange={() => { this.optionSelectedCall('12x3', 'PolesNeeded') }} checked={boardDetails.PolesNeeded === '12x3'} />
                                                    <label htmlFor="poles-needed-8" className="form__control-label button button--3">12x3</label>
                                                </div>
                                                <div className={`form__control radio radio--button ${polesNeededSelected ? ' is-selected' : ''} ${!polesNeededEnabled ? ' is-disabled' : ''}`}>
                                                    <input id="poles-needed-9" type="radio" name="Poles Needed" className="form__control-input"
                                                        onChange={() => { this.optionSelectedCall('15x3', 'PolesNeeded') }} checked={boardDetails.PolesNeeded === '15x3'} />
                                                    <label htmlFor="poles-needed-9" className="form__control-label button button--3">15x3</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="radio-row">
                                            <p className="radio-row__title">
                                                4 rows
                                            </p>
                                            <div className="radio-list">
                                                <div className={`form__control radio radio--button ${polesNeededSelected ? ' is-selected' : ''} ${!polesNeededEnabled ? ' is-disabled' : ''}`}>
                                                    <input id="poles-needed-10" type="radio" name="Poles Needed" className="form__control-input"
                                                        onChange={() => { this.optionSelectedCall('15x4', 'PolesNeeded') }} checked={boardDetails.PolesNeeded === '15x4'} />
                                                    <label htmlFor="poles-needed-10" className="form__control-label button button--3">15x4</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Metal black plate"
                                        enabled={boardDetails.PolesNeeded !== '' && boardDetails.EnclosureType === 'Flush Mount'}
                                        options={boardOptions.MetalBlackPlate}
                                        selectedValue={boardDetails.MetalBlackPlate}
                                        selecteCallback={value => {
                                            this.optionSelectedCall(value, 'MetalBlackPlate')
                                        }}
                                        enabledOptions={this.getMetalBlackPlateEnabledOptions()}
                                    />
                                </div>
                            </div>
                            {
                                this.state.validationMessage &&
                                <div className="modal__validation-msg">
                                    <span className="alert__icon">
                                        <span className="i-exclamation"></span>
                                    </span>
                                    <span dangerouslySetInnerHTML={{ __html: this.state.validationMessage }}></span>
                                </div>
                            }
                        </form>
                        {/* END Step 1 */}

                        {/* Step 2 */}
                        <form className={'form' + (this.state.currentStep === 2 ? ' form-show' : ' form-hide')}>
                            <div className="row">
                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="No. of Incoming Phases"
                                        options={boardOptions.IncomerPhase}
                                        selectedValue={boardDetails.IncomerPhase}
                                        selecteCallback={value => {
                                            this.optionSelectedCall(value, 'IncomerPhase')
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Main incomer type"
                                        enabled={boardDetails.IncomerPhase !== ''}
                                        options={boardOptions.MainSwitchType}
                                        selectedValue={boardDetails.MainSwitchType}
                                        selecteCallback={value => {
                                            this.optionSelectedCall(value, 'MainSwitchType')
                                        }}
                                    />
                                </div>

                                <div className="col-4">
                                    <RadioObjectGroup
                                        title="Circuit breaker kA rating"
                                        enabled={boardDetails.MainSwitchType === 'Circuit breaker'}
                                        options={boardOptions.CircuitBreaker}
                                        selectedValue={boardDetails.CircuitBreaker}
                                        selecteCallback={value => {
                                            this.optionSelectedCall(value, 'CircuitBreaker')
                                        }}
                                        enabledOptions={this.getCircuitBreakerEnabledOptions()}
                                    />
                                </div>

                                <div className="col-8">
                                    <RadioObjectGroup
                                        layout={"layout-auto radio-button-row"}
                                        title="Current Rating"
                                        enabled={boardDetails.CircuitBreaker !== '' || boardDetails.MainSwitchType === 'Switch Isolator'}
                                        options={boardOptions.MainSwitchRating}
                                        selectedValue={boardDetails.MainSwitchRating}
                                        selecteCallback={value => {
                                            this.optionSelectedCall(value, 'MainSwitchRating')
                                        }}
                                        enabledOptions={this.getMainSwitchRatingEnabledOptions()}
                                    />
                                </div>
                            </div>
                            {
                                this.state.validationMessage &&
                                <div className="modal__validation-msg">
                                    <span className="alert__icon">
                                        <span className="i-exclamation"></span>
                                    </span>
                                    <span dangerouslySetInnerHTML={{ __html: this.state.validationMessage }}></span>
                                </div>
                            }
                        </form>
                        {/* END Step 2 */}

                        {/* Step 3 */}
                        <form className={'form' + (this.state.currentStep === 3 ? ' form-show' : ' form-hide')}>
                            <div className="row">
                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Surge Protection"
                                        enabled={boardDetails.MainSwitchType !== ''}
                                        options={boardOptions.SurgeProtection}
                                        selectedValue={boardDetails.SurgeProtection}
                                        selecteCallback={value => {
                                            this.optionSelectedCall(value, 'SurgeProtection')
                                        }}
                                        enabledOptions={this.getSurgeProtectionEnabledOptions()}
                                    />
                                </div>

                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Max. Discharge Capacity"
                                        enabled={boardDetails.SurgeProtection !== '' && boardDetails.SurgeProtection === true}
                                        options={boardOptions.MaxDischargeCapacity}
                                        selectedValue={boardDetails.MaxDischargeCapacity}
                                        selecteCallback={value => {
                                            this.optionSelectedCall(value, 'MaxDischargeCapacity')
                                        }}
                                        enabledOptions={this.getMaxDischargeCapacityEnabledOptions()}
                                    />
                                </div>
                            </div>
                            {
                                this.state.validationMessage &&
                                <div className="modal__validation-msg">
                                    <span className="alert__icon">
                                        <span className="i-exclamation"></span>
                                    </span>
                                    <span dangerouslySetInnerHTML={{ __html: this.state.validationMessage }}></span>
                                </div>
                            }
                        </form>
                        {/* END Step 3 */}
                    </div>

                    {this.renderModalFooter()}
                </div>
            </div>
        )
    }
}