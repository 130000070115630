import React from 'react'
import Axios from 'axios'
import {
    withRouter,
    Switch,
    Route
} from 'react-router-dom'
import Projects from './pages/projects/Projects'
import ProjectDetails from './pages/project-details/commercial/ProjectDetails'
import ProjectDetailsResidential from './pages/project-details/residential/ProjectDetailsResidential'
import Quote from './pages/quote/Quote'
import QuoteResidential from './pages/quote/QuoteResidential'
import { TechnicalInformation } from './pages/technical-information/TechnicalInformation'
import { Home } from './pages/home/Home'
import { Registration } from './pages/registration/Registration'
import ConfigChassis from './pages/config-chassis/ConfigChassis'
import ConfigChassisResidential from './pages/config-chassis/ConfigChassisResidential'
import { apiPostLoginSSOCallback } from './utils/apiCalls'
import { apiCallsCommercial } from './utils/apiCallsCommercial'
import { apiCallsResidential } from './utils/apiCallsResidential'
import analyticsDataLayer from './services/AnalyticsDataLayer'
import { UserProfile } from './pages/user-profile/UserProfile'
const { CONST } = require('./CONST')

const userType = {
    0: 'Large Contractor',
    1: 'Switchboard Manufacturer',
    2: 'Wholesaler',
    3: 'Contractor', //UserIsContractor
    4: 'Consultant', //IsConsultant
}
class App extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isAuthenticating: true,
            userSettings: {}
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search)
        const code = urlParams.get('code')
        const tokenParam = urlParams.get('token')
        const token = window.localStorage.getItem('token')

        if (code) {
            apiPostLoginSSOCallback(code)
                .then(response => {
                    if (response.token) {                        
                        window.localStorage.setItem('token', response.token)
                        this.setAxiosAuthorizationHeader(response.token)
                        // Remove the token from the URL
                        window.history.replaceState({}, document.title, window.location.pathname)


                        try {
                            const arrayToken = response.token.split('.')
                            const decodedToken = JSON.parse(atob(arrayToken[1]))

                            // Need to pass more event data to the data layer
                            analyticsDataLayer.login({
                                'user_id': response.federatedId !== null ? response.federatedId : '', 
                                'user_type': response.ailApplications !== null ? response.ailApplications : '',
                                'company_name': response.companyName !== null ? response.companyName : '',
                                'is_federated_id': response.isFederatedId !== null ? response.isFederatedId : '',
                                'company_id': response.companyId !== null ? response.companyId : '', 
                                'customer_classification_category': response.customerClassificationCategory !== null ? response.customerClassificationCategory : '',
                                'customer_classification_sub_category': response.customerClassificationSubCategory !== null ? response.customerClassificationSubCategory : '',

                                // Currently passing as empty as those would still need to be provided
                                'is_test_user': '',
                                'is_schneider_employee': '',
                                'is_customer_impersonation': ''
                            })
                        } catch (error) {
                            console.error('Error parsing token (Analytics purposes only)', error)
                        }

                        this.initialize()
                    } else {
                        window.alert('Failed to get token from the server')
                    }
                })
                .catch((error) => {
                    // Redirect the user to the home page with the error message in the params
                    // Check that the message is a string, not an object
                    if (typeof error === 'object') {
                        error = JSON.stringify(error)
                    }

                    window.location.href = CONST.PAGES.HOME + `?error=${error}`
                })
        } else if (tokenParam) {
            window.localStorage.setItem('token', tokenParam)
            this.setAxiosAuthorizationHeader(tokenParam)
            this.initialize()
        } else if (token) {
            this.setAxiosAuthorizationHeader(token)
            this.initialize()
        } else {
            // Redirect to the login page if not already on the login page
            if (window.location.pathname.includes(CONST.PAGES.HOME)) {
                this.setState({ isAuthenticating: false })
            } else {
                this.props.history.push(CONST.PAGES.HOME)
                this.setState({ isAuthenticating: false })
            }
        }
    }

    // Set axios authorization header
    setAxiosAuthorizationHeader(token) {
        if (token) {
            Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        } else {
            delete window.axios.defaults.headers.common['Authorization']
        }
    }

    initialize() {
        apiCallsCommercial.apiUserSettings()
            .then((userDataObj) => {
                // For the Medallia integration CLIP-606, we need to expose some of the global variables
                if (userDataObj !== null) {
                    window.surveyFederatedId = userDataObj.UserGuid
                    window.surveyLastName = userDataObj.UserLastName
                    window.surveyFirstName = userDataObj.UserFirstName
                    window.surveyEmailId = userDataObj.UserEmail
                    window.surveyPhoneNumber = userDataObj.UserPhone
                }

                this.setState({
                    userSettings: userDataObj,
                    isAuthenticating: false
                })

                this.props.history.push('/')
            })
            .catch(() => {
                // Failed to get user setting, then take the user back to the login page
                if (window.location.href.indexOf('localhost') > -1) {
                    window.alert('You are recognized as working in the local environment, but not authorized to call APIs, please check the documentation.')
                } else {
                    this.props.history.push(CONST.PAGES.HOME)
                }
            })

        this.props.history.listen(location => {
            const userTypeName = userType?.[this.state?.userSettings?.UserType] || ''
            this.processDataLayerPageView(location, userTypeName)
        })
    }

    processDataLayerPageView(location, userType) {
        const path = location?.pathname === '/' ? 'home' : location?.pathname?.replace(/\//g, '')
        // All pathnames follow the convention of same names, but the residential path has the additional word "-residential" in all the paths
        // Use this to determine if the user is on the residential or commercial path
        const isCommercial = location?.pathname.includes('residential') ? false : true
        // If the pathnames includes in whole or partially path from any of these pages PROJECT_DETAILS, CONFIGURE_CHASSIS, QUOTE it 
        // means the user is on the project page. This is the best compromise for now, but if any drastic changes are made or new pages
        // introduced, this logic might need to be updated
        const isOnProjectPage = location?.pathname.includes(CONST.PAGES.PROJECT_DETAILS) || location?.pathname.includes(CONST.PAGES.CONFIGURE_CHASSIS) || location?.pathname.includes(CONST.PAGES.QUOTE)
        const queryString = location?.search || ''
        const params = new URLSearchParams(queryString)
        const projectId = params.get('orderID') || params.get('projectID') || ''
        const boardID = params.get('boardid') || params.get('boardID') || ''
        const callDataLayer = () => {
            // For more details on this implementation please refer to https://journey-digital.atlassian.net/browse/CLIP-628
            analyticsDataLayer.pageView({
                'digital_platform': 'FlexSelect',
                'digital_platform_country': 'au',
                'environment': process.env.REACT_APP_ENVIRONMENT,
                // Pass empty if it's not a project page, otherwise pass "Power Products" for commercial and "H&D" for residential
                'business_unit': isOnProjectPage ? (isCommercial ? 'Power Products' : 'H&D') : '',
                'business_divison': '', // Should be left empty
                'configurator_name': 'FlexSelect',
                'page_category': '/flex',
                'page_language': 'en',
                'page_sub_category': projectId,
                'page_sub_sub_category': boardID,
                'page_top_category': this.getTopCategory(location) || '',
                'user_persona': userType,
                // Pass empty if it's not a project page, otherwise pass project type
                'project_type': isOnProjectPage ? (isCommercial ? 'Commercial' : 'residential') : '',
                'project_name': projectName,
                'project_id' : projectId,         
                'template': path
            })
        }

        let projectName = ''
        if (isOnProjectPage) {
            const apiCall = isCommercial ? apiCallsCommercial.apiGetProject : apiCallsResidential.apiGetProject
            apiCall(projectId).then(response => {
                projectName = response.Title
                callDataLayer()
            })
        } else {
            callDataLayer()
        }
    }

    getTopCategory(location) {
        return location?.pathname + (location?.search ? location.search : '') || ''
    }

    render() {
        if (this.state.isAuthenticating) {
            // We can improve this and put some global loader
            return ''
        } else {
            return (
                <Switch>
                    <Route path={CONST.PAGES.TECHNICAL_INFORMATION}>
                        <TechnicalInformation />
                    </Route>
                    <Route path={CONST.PAGES.PROJECT_DETAILS}>
                        <ProjectDetails />
                    </Route>
                    <Route path={CONST.PAGES.PROJECT_DETAILS_RESIDENTIAL}>
                        <ProjectDetailsResidential />
                    </Route>
                    <Route path={CONST.PAGES.CONFIGURE_CHASSIS}>
                        <ConfigChassis isCommercial={true} />
                    </Route>
                    <Route path={CONST.PAGES.CONFIGURE_CHASSIS_RESIDENTIAL}>
                        <ConfigChassisResidential isCommercial={false} />
                    </Route>
                    <Route path={CONST.PAGES.QUOTE}>
                        <Quote />
                    </Route>
                    <Route path={CONST.PAGES.QUOTE_RESIDENTIAL}>
                        <QuoteResidential />
                    </Route>
                    <Route path={CONST.PAGES.REGISTER}>
                        <Registration />
                    </Route>
                    <Route path={CONST.PAGES.HOME}>
                        <Home />
                    </Route>
                    <Route path={CONST.PAGES.PROFILE}>
                        <UserProfile />
                    </Route>
                    <Route path="/">
                        <Projects />
                    </Route>
                </Switch>
            )
        }
    }
}

export default withRouter(App)
