import React, { Component } from 'react'
import jQuery from "jquery"
import cloneDeep from 'lodash.clonedeep'
import RadioObjectGroup from './../../../components/radio-object-group/RadioObjectGroup'
import { apiCallsCommercial } from '../../../utils/apiCallsCommercial'
import ChassisOptionsForm from '../../../components/config-chassis/commercial/ChassisOptionsForm'
import GlobalOverlay from '../../../components/global-overlay/GlobalOverlay'
import { CONST } from '../../../CONST'
import BoardDetailsFormBusinessLogic from './../BoardDetailsFormBusinessLogic'
import analyticsDataLayer from '../../../services/AnalyticsDataLayer'

export default class BoardDetails extends Component {
    constructor(props) {
        super(props)

        // Internal states that no one else needs to know about holds info on the selection options
        this.initStates(this.props)

        // Setup the business logic
        this.logic = new BoardDetailsFormBusinessLogic(
            props.boardOptions,
            props.boardOptionData,
            this.state.boardDetails
        )
    }

    componentDidMount() {
        jQuery('body').addClass('modal-is-open')

        let boardID = null
        if (!!this.props.board) {
            boardID = this.props.board.ItemID
        }

        apiCallsCommercial.apiGetBoardMiscParts(boardID).then((miscData) => {
            this.setState({ chassisOptions: miscData.FittedOptions })
        })

        apiCallsCommercial.apiGetParentChild().then((parentChildData) => {
            this.setState({ parentChildData: parentChildData })
        })

        apiCallsCommercial.apiGetAllParts().then((allPartsData) => {
            this.setState({ commercialAllPartsAvailable: allPartsData })
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.board && !nextProps.board) {
            return
        }

        if (!this.props.board || !nextProps.board || this.props.board.ItemID !== nextProps.board.ItemID) {
            this.initStates(nextProps, true)
        }
    }

    getBoardMeterTypeValue(board) {
        let powerMeterCode = null
        let existingPowerMeterCode = board['PowerMeter' + board.ChassisCount + 'Code']

        if (existingPowerMeterCode !== '' && existingPowerMeterCode !== null) {
            powerMeterCode = existingPowerMeterCode.substr(0, 2)
        }

        return powerMeterCode
    }

    initStates(propsData, useSetState = false) {
        // The board data on a saved board is split in both the project board and the board type data, so we need to get it from both
        const existingBoard = propsData.board
            ? propsData.boardOptionData.find(item => item.ItemID === propsData.board.BoardTypeID)
            : null
        const updatedState = {
            boardDetails: {
                Name: propsData.board ? propsData.board.Name : '',
                IPRating: existingBoard ? existingBoard.IPRating : null,
                BoardColour: existingBoard ? existingBoard.BoardColour : null,
                MainSwitchType: existingBoard ? existingBoard.MainSwitchType : null,
                RatingAmperes: existingBoard ? existingBoard.RatingAmperes : null,
                ChassisCount: existingBoard ? existingBoard.ChassisCount : null,
                ChassisType: existingBoard ? existingBoard.ChassisType : null,
                BreakingCapacity: existingBoard ? propsData.board.BreakingCapacity : null,
                Metering: existingBoard ? existingBoard.Chassis1PowerMeterAmperes > 0 : null,
                MeterType: existingBoard ? this.getBoardMeterTypeValue(existingBoard) : null,
                Chassis1PoleCount27mm: existingBoard ? existingBoard.Chassis1PoleCount27mm : null,
                Chassis1PoleCount: existingBoard ? existingBoard.Chassis1PoleCount : null,
                Chassis2PoleCount: existingBoard ? existingBoard.Chassis2PoleCount : null
            },
            board: existingBoard ? propsData.board : null,
            isExistingBoard: existingBoard ? true : false,
            currentStep: 1, // Used to indicate the step the design is divided
            currentStepStatus: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false
            },
            canGoNextStep: false,
            canAddOptionalExtras: true,
            hasUsedBackButton: false,
            chassisOptions: [],
            parentChildData: [],
            commercialAllPartsAvailable: [],
            selectedChassisOptions: [],
            availabilityValidationMessages: [],
            isLoading: false,
            existingBoard: false
        }

        // If it is an existing board, then we allow the user to skip between the step as if he used a back button
        if (updatedState.isExistingBoard) {
            updatedState.hasUsedBackButton = true
        }

        if (useSetState) {
            this.setState(updatedState)
        } else {
            this.state = updatedState
        }
    }

    getCurrentBoardData() {
        return this.props.boardOptionData.find(item => item.ItemID === this.state.board.BoardTypeID)
    }

    // Update the selected option value in local state
    optionSelectedCall(value, key) {
        const updatedBoard = cloneDeep(this.state.boardDetails)
        updatedBoard[key] = value

        // Update values on the affected related fields
        // When users select ChassisCount or Chassis1PoleCount this affects other fields.
        this.logic.setAndClearRelatedFields(updatedBoard)

        this.setState({ boardDetails: updatedBoard }, () => {
            // If the user has used the back button, the don't automatically skip the steps, we will show 
            if (this.canGoNextStep()) {
                // If the current step is a step 4 and it is a new board, then we need to save it first
                // if (this.state.currentStep === 4 && !this.state.isExistingBoard) {
                //     this.createBoardCall()
                // } else {   
                // Temporary comment autoskipping to the next step
                // if (!this.state.hasUsedBackButton) {
                //     // Add one second delay to improve user perception of the selected values
                //     window.setTimeout(() => { 
                //         this.goNextStep()
                //     }, 1000)
                // } else {
                this.setState({ canGoNextStep: true })
                // }
                // }
            } else {
                this.setState({ canGoNextStep: false })
            }
        })
    }

    createBoardCall() {
        const finalSelections = cloneDeep(this.state.boardDetails)
        const finalFilteredBoardData = this.logic.setAndClearRelatedFields(finalSelections)

        // Assumption that the board type options from the server will always have distinct setup
        if (finalFilteredBoardData.length !== 1 && !this.checkIfFilterDataAreTheSame(finalFilteredBoardData)) {
            this.setState({ validationMessage: 'Please make sure all fields are filled in' })
            return
        }

        // Create the final board object to be passed to the server
        let finalBoard = {
            BoardTypeID: finalFilteredBoardData[0].ItemID,
            Name: finalSelections.Name,
            BreakingCapacity: finalSelections.BreakingCapacity
        }

        finalBoard.ProjectID = this.props.projectID
        this.setState({ isLoading: true })

        apiCallsCommercial.apiSaveBoard(finalBoard)
            .then(boardData => {
                this.setState({
                    existingBoard: true,
                    board: boardData
                })

                if (!this.state.currentStepStatus[4]) {
                    analyticsDataLayer.addBoard({
                        "item_board_name": boardData.Name,
                        "item_board_id": boardData.ItemID.toString(),
                        "configuration_name": boardData.Name,
                        "configuration_id": boardData.ItemID.toString(),                 
                        "project_name": this.props.projectName,
                        "project_id": this.props.projectID.toString(),
                        "project_type": "Commercial",
                        "funnel_step": '4',
                        "funnel_total_steps": '5'
                    })
                }
                this.setState({
                    currentStepStatus: {
                        ...this.state.currentStepStatus,
                        4: true
                    }
                })

                return apiCallsCommercial.apiGetBoardMiscParts(boardData.ItemID)
            })
            .then(miscData => {
                this.setState({ chassisOptions: miscData.FittedOptions })
            })
            .catch(error => {
                window.alert(`There was an error creating the board: ${error.message}`)
            })
            .then(() => {
                this.setState({ isLoading: false, currentStep: this.state.currentStep + 1 })
            })
    }

    checkIfFilterDataAreTheSame(filterData) {
        let allTheSame = true
        const checkFields = [
            'ChassisCount',
            'ChassisType',
            'Chassis1PoleCount',
            'Chassis1PowerMeterAmperes',
            'Chassis2PoleCount',
            'Chassis1PoleCount27mm',
            'RatingAmperes',
            'MainSwitchType',
            'BoardColour',
            'IPRating'
        ]

        filterData.forEach(item => {
            checkFields.forEach(field => {
                if (filterData[0][field] !== item[field]) {
                    allTheSame = false
                }
            })
        })

        return allTheSame
    }

    updateBoardCall() {
        const finalSelections = cloneDeep(this.state.boardDetails)
        const finalFilteredBoardData = this.logic.setAndClearRelatedFields(finalSelections)

        // Assumption that the board type options from the server will always have distinct setup
        if (finalFilteredBoardData.length !== 1 && !this.checkIfFilterDataAreTheSame(finalFilteredBoardData)) {
            this.setState({ validationMessage: 'Please make sure all fields are filled in' })
            alert('Please make sure all fields are filled in')
            return
        }

        // Create the final board object to be passed to the server
        let finalBoard = {
            BoardTypeID: finalFilteredBoardData[0].ItemID,
            Name: finalSelections.Name,
            BreakingCapacity: finalSelections.BreakingCapacity
        }

        finalBoard.ProjectID = this.props.projectID
        finalBoard.ItemID = this.state.board.ItemID

        this.setState({ isLoading: true })

        apiCallsCommercial.apiSaveBoard(finalBoard)
            .then(boardData => {
                this.setState({
                    existingBoard: true,
                    board: boardData
                })

                if (this.props.onUpdateCallback !== undefined) {
                    this.props.onUpdateCallback(boardData)
                }

                if (!this.state.isExistingBoard) {
                    analyticsDataLayer.addBoard({
                        "item_board_name": boardData.Name,
                        "item_board_id": boardData.ItemID.toString(),
                        "configuration_name": boardData.Name,
                        "configuration_id": boardData.ItemID.toString(), 
                        "project_name": this.props.projectName,
                        "project_id": this.props.projectID.toString(),
                        "project_type": "Commercial",
                        "funnel_step": '5',
                        "funnel_total_steps": '5'
                    })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                window.alert(`There was an error creating the board: ${error.message}`)
            })
            .then(() => {
                this.updateSelectedChassisOptions(this.state.selectedChassisOptions, this.state.existingBoard)
            })
    }

    updateBoard() {
        analyticsDataLayer.ctaButtonClick({
            cta_name: 'Add Board',
            cta_link: window.location.href,
            cta_location: 'board details page'
        })
        this.updateBoardCall()
    }

    updateSelectedChassisOptions(selectedOptions, redirectToChassis) {
        this.setState({ isLoading: true })

        // The options data needs to keep the exisitng options and set the isDeleted flag if it has been unchecked
        const newDataArray = this.state.board.ProjectBoardFittedOptionCollection.map(item => {
            return {
                FittedOptionItemID: item.FittedOptionItemID,
                ItemID: item.ItemID,
                IsDeleted: !selectedOptions.includes(item.FittedOptionItemID)
            }
        })

        selectedOptions.forEach(value => {
            // Add in new option id if its not in the existing board option collections
            const existingOption = newDataArray.find(item => item.FittedOptionItemID === value)
            if (!existingOption) {
                newDataArray.push({ FittedOptionItemID: value })
            }
        })

        apiCallsCommercial.apiSaveBoardChassisOptions(this.props.projectID, this.state.board.ItemID, newDataArray)
            .then(boardData => {
                this.setState({ board: boardData }, () => {
                    if (redirectToChassis !== undefined && window.location.href.indexOf(CONST.PAGES.CONFIGURE_CHASSIS) === -1) {
                        this.props.redirectToChassis(this.props.projectID, this.state.board.ItemID)
                    }

                    if (this.props.onUpdateCallback !== undefined) {
                        this.props.onUpdateCallback(boardData)
                    }
                })
            })
            .catch(error => {
                alert(error)
            })
            .then(() => {
                this.setState({ isLoading: false })
                this.closeModal()
            })
    }

    // Handle switching of the steps in the UI, this is a very specific manual division
    canGoNextStep = () => {
        let canGoNextStep = false
        if (this.state.currentStep === 1 && this.state.boardDetails.BoardColour !== null) {
            canGoNextStep = true
        } else if (this.state.currentStep === 2 && this.state.boardDetails.RatingAmperes !== null) {
            canGoNextStep = true
        } else if (this.state.currentStep === 3) {
            // In this case the last input will be disabled, so if Chassis isn't Hybrid then Matering is our last field in the form
            if (this.state.boardDetails.Metering !== null && this.state.boardDetails.ChassisType !== 'Hybrid') {
                if (this.state.boardDetails.Metering === false || this.state.boardDetails.MeterType !== null) {
                    canGoNextStep = true
                }
            } else if (this.state.boardDetails.Chassis1PoleCount27mm !== null) {
                canGoNextStep = true
            }
        } else if (this.state.currentStep === 4) {
            if ((this.state.boardDetails.ChassisCount === 1 && this.state.boardDetails.Chassis1PoleCount !== null) ||
                (this.state.boardDetails.ChassisCount === 2 && this.state.boardDetails.Chassis2PoleCount !== null)
            ) {
                canGoNextStep = true

                // Here we finally know what is the final board configuration, so we can check the board components selections
                // and if they are low stock or out of stock, if any of the components is out of stock, then we can't go to the next step
                const finalSelections = cloneDeep(this.state.boardDetails)
                const finalFilteredBoardData = this.logic.setAndClearRelatedFields(finalSelections)

                // Reset availability messages
                this.setState({ availabilityValidationMessages: [] })

                /*
                    At this stage we know these components that were selected for the final board, e.g.:

                    Isolator/Main Switch

                        "IsolatorSize": "CVS 100A MAIN SWITCH"

                    Enclosure & Power Meter

                        "EnclosureReference": "MB200CBOX_LDB"
                        "PowerMeter1Code": "DM-ISO-100A-C1"
                        "PowerMeter2Code": ""

                    Chassis

                        "ChassisReference": "CHASSIS 1 ISOBAR 18P"

                    We can't directly check those values against the reference inside commercialAllPartsAvailable, first we need to find them
                    inside the parentChildData and find the child component reference
                */

                // TODO: this isn't really DRY, will need to refactor 
                const availablityMessages = []
                console.log('Final board data:', finalFilteredBoardData[0])
                if (!!finalFilteredBoardData[0].IsolatorSize) {
                    const mainSwitchParentItem = this.state.parentChildData.find(item => item.ParentItemReference === finalFilteredBoardData[0].IsolatorSize)
                    if (mainSwitchParentItem !== undefined) {
                        const mainSwitchChildItemReference = mainSwitchParentItem.ChildItemReference
                        const mainSwitchAvailability = this.state.commercialAllPartsAvailable.find(item => item.ItemCode === mainSwitchChildItemReference)

                        if (mainSwitchAvailability !== undefined) {
                            if (mainSwitchAvailability.IsLowStock) {
                                availablityMessages.push(`Isolator ${mainSwitchAvailability.Description} Low stock, longer lead times may apply`)
                            } else if (!mainSwitchAvailability.IsInStock) {
                                availablityMessages.push(`Isolator ${mainSwitchAvailability.Description} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`)

                                canGoNextStep = false
                            }
                        }
                    }
                }
                
                if (!!finalFilteredBoardData[0].EnclosureReference) {
                    const enclosureParentItem = this.state.parentChildData.find(item => item.ParentItemReference === finalFilteredBoardData[0].EnclosureReference)
                    if (enclosureParentItem !== undefined) {
                        const enclosureChildItemReference = enclosureParentItem.ChildItemReference
                        const enclosureAvailability = this.state.commercialAllPartsAvailable.find(item => item.ItemCode === enclosureChildItemReference)

                        if (enclosureAvailability !== undefined) {
                            if (enclosureAvailability.IsLowStock) {
                                availablityMessages.push(`Enclosure ${enclosureAvailability.Description} Low stock, longer lead times may apply`)
                            } else if (!enclosureAvailability.IsInStock) {
                                availablityMessages.push(`Enclosure ${enclosureAvailability.Description} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`)

                                canGoNextStep = false
                            }
                        }
                    }
                }

                if (!!finalFilteredBoardData[0].PowerMeter1Code) {
                    const meterParentItem = this.state.parentChildData.find(item => item.ParentItemReference === finalFilteredBoardData[0].PowerMeter1Code)
                    if (meterParentItem !== undefined) {
                        const meterChildItemReference = meterParentItem.ChildItemReference
                        const meterAvailability = this.state.commercialAllPartsAvailable.find(item => item.ItemCode === meterChildItemReference)

                        if (meterAvailability !== undefined) {
                            if (meterAvailability.IsLowStock) {
                                availablityMessages.push(`Power Meter ${meterAvailability.Description} Low stock, longer lead times may apply`)
                            } else if (!meterAvailability.IsInStock) {
                                availablityMessages.push(`Power Meter ${meterAvailability.Description} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`)

                                canGoNextStep = false
                            }
                        }
                    }
                }

                if (!!finalFilteredBoardData[0].PowerMeter2Code) {
                    const meterParentItem = this.state.parentChildData.find(item => item.ParentItemReference === finalFilteredBoardData[0].PowerMeter2Code)
                    if (meterParentItem !== undefined) {
                        const meterChildItemReference = meterParentItem.ChildItemReference
                        const meterAvailability = this.state.commercialAllPartsAvailable.find(item => item.ItemCode === meterChildItemReference)

                        if (meterAvailability !== undefined) {
                            if (meterAvailability.IsLowStock) {
                                availablityMessages.push(`Power Meter ${meterAvailability.Description} Low stock, longer lead times may apply`)
                            } else if (!meterAvailability.IsInStock) {
                                availablityMessages.push(`Power Meter ${meterAvailability.Description} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`)

                                canGoNextStep = false
                            }
                        }
                    }
                }

                if (!!finalFilteredBoardData[0].ChassisReference) {
                    const chassisParentItem = this.state.parentChildData.find(item => item.ParentItemReference === finalFilteredBoardData[0].ChassisReference)
                    if (chassisParentItem !== undefined) {
                        const chassisChildItemReference = chassisParentItem.ChildItemReference
                        const chassisAvailability = this.state.commercialAllPartsAvailable.find(item => item.ItemCode === chassisChildItemReference)
                        if (chassisAvailability !== undefined) {
                            if (chassisAvailability.IsLowStock) {
                                availablityMessages.push(`Chassis ${chassisAvailability.Description} Low stock, longer lead times may apply`)
                            } else if (!chassisAvailability.IsInStock) {
                                availablityMessages.push(`Chassis ${chassisAvailability.Description} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`)

                                canGoNextStep = false
                            }
                        }
                    }
                }

                this.setState({ availabilityValidationMessages: availablityMessages })
            }

        }

        return canGoNextStep
    }

    renderModalHeader = () => {
        let progress = this.state.currentStep * 20
        if (this.state.isExistingBoard) {
            progress = 100
        }

        const isNotAllowedToGoStep2 = (this.state.currentStep === 1 && !this.state.isExistingBoard && !this.state.canGoNextStep)
        const isNotAllowedToGoStep3 = (this.state.currentStep === 2 && !this.state.canGoNextStep) || (this.state.currentStep < 2 && !this.state.isExistingBoard)
        const isNotAllowedToGoStep4 = (this.state.currentStep === 3 && !this.state.canGoNextStep) || (this.state.currentStep < 3 && !this.state.isExistingBoard)

        return (
            <div className="modal__header">
                <button className="modal__close button button--icon" type="button" aria-label="close modal" onClick={this.closeModal}>
                    <span className="i-close"></span>
                </button>
                <h4 className="modal__title h3">
                    {this.state.isExistingBoard && <span>Edit board</span>}
                    {!this.state.isExistingBoard && <span>Add a board</span>}
                </h4>
                <div className="steps">
                    <div className={'step' + (this.state.currentStep === 1 ? ' is-active' : ' is-done')} onClick={() => { this.goStep(1) }}>
                        <span className="step__number">1</span>
                        <span className="step__text">Set-Up</span>
                    </div>
                    <div className={'step' +
                        (this.state.currentStep === 2 ? ' is-active' : '') +
                        (this.state.currentStep > 2 || this.state.isExistingBoard ? ' is-done' : '') +
                        (isNotAllowedToGoStep2 ? ' not-allowed' : '')
                    } onClick={() => { if (!isNotAllowedToGoStep2) this.goStep(2) }}>
                        <span className="step__number">2</span>
                        <span className="step__text">Main Switch</span>
                    </div>
                    <div className={'step' +
                        (this.state.currentStep === 3 ? ' is-active' : '') +
                        (this.state.currentStep > 3 || this.state.isExistingBoard ? ' is-done' : '') +
                        (isNotAllowedToGoStep3 ? ' not-allowed' : '')
                    } onClick={() => { if (!isNotAllowedToGoStep3) this.goStep(3) }}>
                        <span className="step__number">3</span>
                        <span className="step__text">Chassis</span>
                    </div>
                    <div className={'step' +
                        (this.state.currentStep === 4 ? ' is-active' : '') +
                        (this.state.currentStep > 4 || this.state.isExistingBoard ? ' is-done' : '') +
                        (isNotAllowedToGoStep4 ? ' not-allowed' : '')
                    } onClick={() => { if (!isNotAllowedToGoStep4) this.goStep(4) }}>
                        <span className="step__number">4</span>
                        <span className="step__text">Size</span>
                    </div>
                    <div className={'step' +
                        (this.state.currentStep === 5 ? ' is-active' : '') +
                        (this.state.isExistingBoard ? ' is-done' : '') +
                        (!this.state.isExistingBoard ? ' not-allowed' : '')
                    } onClick={() => { this.goStep(5) }}>
                        <span className="step__number">5</span>
                        <span className="step__text">Optional Extras</span>
                    </div>
                </div>
                <div className="steps-progress">
                    <div className="steps-progress-bar" style={{ width: progress + '%' }}></div>
                </div>
            </div>
        )
    }

    renderModalFooter = () => {
        return (
            <div className="modal__footer">
                {
                    this.state.currentStep > 1 &&
                    <button className="button button--back" onClick={() => { this.goStepBack() }}>
                        <span className="button__text">
                            Previous
                        </span>
                    </button>
                }
                {
                    // ((this.state.hasUsedBackButton && this.state.canGoNextStep) || (this.state.isExistingBoard && this.state.currentStep !== 5)) &&
                    (!this.state.isExistingBoard && this.state.currentStep !== 5) &&
                    <button className="button button--1" disabled={!this.state.canGoNextStep} onClick={() => { this.goNextStep() }}>
                        <span className="button__text">
                            Next
                        </span>
                    </button>
                }

                {
                    (this.state.isExistingBoard && this.state.currentStep !== 5) &&
                    <button className="button button--forward" onClick={() => { this.goNextStep() }}>
                        <span className="button__text">
                            Next
                        </span>
                    </button>
                }

                {
                    (this.state.isExistingBoard && this.state.currentStep < 5) &&
                    <button className="button button--1" onClick={() => { this.updateBoard() }}>
                        <span className="button__text">
                            Save &amp; Close
                        </span>
                    </button>
                }

                {/* This is only showing on last step */}
                {
                    this.state.currentStep === 5 &&
                    <button className="button button--1" disabled={!this.state.canAddOptionalExtras} onClick={() => { this.updateBoard() }}>
                        <span className="button__text">
                            {this.state.isExistingBoard && <span>Update board</span>}
                            {!this.state.isExistingBoard && <span>Add board</span>}
                        </span>
                    </button>
                }
            </div>
        )
    }

    goStepBack = () => {
        this.setState({
            currentStep: this.state.currentStep - 1,
            hasUsedBackButton: true
        }, () => this.setState({ canGoNextStep: this.canGoNextStep() }))
    }

    goNextStep = () => {
        if (this.state.currentStep === 4 && !this.state.isExistingBoard && !this.state.existingBoard) {
            this.createBoardCall()
        } else {
            this.setState({
                currentStep: this.state.currentStep + 1
            }, () => this.setState({ canGoNextStep: this.canGoNextStep() }))
            
            if (!this.state.currentStepStatus[this.state.currentStep]) {
                analyticsDataLayer.addBoard({
                    "item_board_name": '',
                    "item_board_id": '',
                    "configuration_name": '',
                    "configuration_id": '', 
                    "project_name": this.props.projectName,
                    "project_id": this.props.projectID.toString(),
                    "project_type": 'Commercial',
                    "funnel_step": this.state.currentStep,
                    "funnel_total_steps": '5'
                })
            }

            this.setState({
                currentStepStatus: {
                    ...this.state.currentStepStatus,
                    [this.state.currentStep]: true
                }
            })

        }
    }

    goStep = (stepNumber) => {
        if (this.state.isExistingBoard || (!this.state.isExistingBoard && stepNumber !== 5)) {
            this.setState({
                currentStep: stepNumber
            }, () => this.setState({ canGoNextStep: this.canGoNextStep() }))
        }
    }

    closeModal = () => {
        jQuery('body').removeClass('modal-is-open')

        if (this.props.closeCallback !== undefined) {
            this.props.closeCallback(this.state.board)
        }
    }

    // This function returns the array of Board Option IDs of the options applied to the current board
    getSelectedChassisOptionArray() {
        if (!this.state.board.ProjectBoardFittedOptionCollection || !this.state.board.ProjectBoardFittedOptionCollection.length) {
            return []
        }

        // Loop throught the collection of option object and return an array of the selected options ID
        const selected = this.state.board.ProjectBoardFittedOptionCollection.map(item => {
            return item.FittedOptionItemID
        })

        return selected
    }

    // Check if there is a breaker covering chassis position index 2
    checkIfIndex2IsFilled() {
        if (!this.state.board.ProjectBoardBreakerCollection || !this.state.board.ProjectBoardBreakerCollection.length) {
            return false
        }

        const boardData = this.getCurrentBoardData()
        const largePoleCount = boardData.ChassisType === 'Hybrid' ? boardData.Chassis1PoleCount27mm : 0
        const checkOptions = this.state.board.ProjectBoardBreakerCollection.filter(item => {
            // We don't use this way of searching for breker data anymore. Leave it anyway so we can see a console log and find during client testing
            // if we missed anything, this can be very helpfull in debugging and hard to find again if we remove it
            // If you still see this code after June 2021, then please remove it
            const breakerObj = this.props.boardOptionData.find(dataItem => dataItem.ItemID === item.BreakerTypeItemID)
            if (breakerObj === undefined) {
                console.warn('Couldn\'t find corresponding board option data for the breaker object')
            }

            return (item.ChassisPositionIndex === (2 + largePoleCount) || (item.ChassisPositionIndex === (4 + largePoleCount) && item.BreakerTypeReadOnly.PoleCount >= 3))
        })

        return (checkOptions.length > 0)
    }

    selectChassisOptionalExtras = (selectedOptions) => {
        let canAddOptionalExtras = true
        const availablityMessages = []
        if (this.state.chassisOptions.length > 0) {        
            selectedOptions.forEach(optionItemID => {
                const optionData = this.state.chassisOptions.find(item => item.ItemID === optionItemID)
                
                // The optionData.BOMReference in the case of optional extras is the Parent Item Reference inside 
                // the Parent-Child List, so we can find the Child Item Reference and check the availability
                const BOMElement = this.state.parentChildData.find(item => item.ParentItemReference === optionData.BOMReference)
                if (BOMElement !== undefined) {
                    const BOMElementAvailability = this.state.commercialAllPartsAvailable.find(item => item.ItemCode === BOMElement.ChildItemReference)
                    
                    if (BOMElementAvailability.IsLowStock) {
                        availablityMessages.push(`${BOMElementAvailability.Description} Low stock, longer lead times may apply`)
                    } else if (!BOMElementAvailability.IsInStock) {
                        availablityMessages.push(`${BOMElementAvailability.Description} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`)
                        
                        canAddOptionalExtras = false
                    }
                }
            })
        }

        this.setState({ 
            selectedChassisOptions: selectedOptions,
            availabilityValidationMessages: availablityMessages,
            canAddOptionalExtras: canAddOptionalExtras
         })
    }

    render() {
        const boardOptions = this.props.boardOptions
        const boardDetails = this.state.boardDetails

        return (
            <div id="add-board" className="modal modal--steps add-board-modal">
                {
                    this.state.isLoading &&
                    <GlobalOverlay>
                        <div>
                            <div className="globalOverlay__message">
                                <span className="globalOverlay__message-spinner">
                                    <span className="spinner"></span>
                                </span>
                                {
                                    this.state.isExistingBoard ?
                                        <span className="h3">Updating</span>
                                        :
                                        <span className="h3">
                                            {this.state.currentStep === 5 ? 'Adding board' : 'Saving'}
                                        </span>
                                }
                            </div>
                        </div>
                    </GlobalOverlay>
                }

                <div className="modal-container">
                    {this.renderModalHeader()}

                    <div className="modal__body">
                        {/* Step 1 */}
                        <form className={'form' + (this.state.currentStep === 1 ? ' form-show' : ' form-hide')}>
                            <div className="row">
                                <div className="col-6">
                                    <div className={'form__group form__group--step is-active' +
                                        (this.state.boardDetails.Name !== null && this.state.boardDetails.Name !== '' ? ' is-done' : '')
                                    }>
                                        <label className="form-label-step" htmlFor="board-create-name">
                                            <div className="form-label-step__icon">
                                                <span className="i-check"></span>
                                            </div>
                                            <span className="form-label-step__text">
                                                Board Name
                                            </span>
                                        </label>
                                        <input id="board-create-name" className="form__input input" type="text" name="nameNewProject" autoFocus
                                            onChange={event => {
                                                this.optionSelectedCall(event.target.value, 'Name')
                                            }}
                                            maxLength="128"
                                            value={boardDetails.Name}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="IP Rating"
                                        options={boardOptions.OptionsIPRating}
                                        selectedValue={boardDetails.IPRating}
                                        selecteCallback={value => this.optionSelectedCall(value, 'IPRating')}
                                        enabledOptions={this.logic.getEnableOptions('IPRating')}
                                    />
                                </div>
                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Enclosure Type / Colour"
                                        options={boardOptions.OptionsBoardColour}
                                        selectedValue={boardDetails.BoardColour}
                                        selecteCallback={value => this.optionSelectedCall(value, 'BoardColour')}
                                        enabledOptions={this.logic.getEnableOptions('BoardColour')}
                                    />
                                </div>
                            </div>
                        </form>

                        {/* Step 2 */}
                        <form className={'form' + (this.state.currentStep === 2 ? ' form-show' : ' form-hide')}>
                            <div className="row">
                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Main Switch Type"
                                        options={boardOptions.OptionsMainSwitchType}
                                        selectedValue={boardDetails.MainSwitchType}
                                        selecteCallback={value => this.optionSelectedCall(value, 'MainSwitchType')}
                                        enabledOptions={this.logic.getEnableOptions('MainSwitchType')}
                                    />
                                </div>

                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Main Switch Rating"
                                        options={boardOptions.OptionsRatingAmperes}
                                        selectedValue={boardDetails.RatingAmperes}
                                        selecteCallback={value => this.optionSelectedCall(value, 'RatingAmperes')}
                                        enabledOptions={this.logic.getEnableOptions('RatingAmperes')}
                                    />
                                </div>
                            </div>
                        </form>

                        {/* Step 3 */}
                        <form className={'form' + (this.state.currentStep === 3 ? ' form-show' : ' form-hide')}>
                            <div className="row">
                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Number Of Chassis"
                                        options={boardOptions.OptionsChassisCount}
                                        selectedValue={boardDetails.ChassisCount}
                                        selecteCallback={value => this.optionSelectedCall(value, 'ChassisCount')}
                                        enabledOptions={this.logic.getEnableOptions('ChassisCount')}
                                    />
                                </div>

                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Chassis Type"
                                        options={boardOptions.OptionsChassisType}
                                        selectedValue={boardDetails.ChassisType}
                                        selecteCallback={value => this.optionSelectedCall(value, 'ChassisType')}
                                        enabledOptions={this.logic.getEnableOptions('ChassisType')}
                                    />
                                </div>
                            </div>
                            <hr />

                            {
                                boardDetails.ChassisCount === 1 && boardDetails.Metering === true && boardDetails.MeterType === 'DM' &&
                                <p className="alert alert--warning">
                                    <span className="alert__icon">
                                        <span className="i-exclamation"></span>
                                    </span>
                                    <span className="alert__text">
                                        The meter may be supplied as single or dual CT depending on the stocks availability
                                    </span>
                                </p>
                            }
                            <div className="row">
                                <div className="col-3">
                                    <RadioObjectGroup
                                        title="MCB/RCBO Breaking Capacity"
                                        options={boardOptions.OptionsBreakingCapacity}
                                        selectedValue={boardDetails.BreakingCapacity}
                                        selecteCallback={value => this.optionSelectedCall(value, 'BreakingCapacity')}
                                        enabledOptions={this.logic.getEnableOptions('BreakingCapacity')}
                                    />
                                </div>

                                <div className="col-3">
                                    <RadioObjectGroup
                                        title="Add Metering"
                                        options={boardOptions.OptionsMetering}
                                        selectedValue={boardDetails.Metering}
                                        selecteCallback={value => this.optionSelectedCall(value, 'Metering')}
                                        enabledOptions={this.logic.getEnableOptions('Metering')}
                                    />
                                </div>

                                <div className="col-3">
                                    <RadioObjectGroup
                                        title="Meter Type"
                                        options={boardOptions.OptionsMeterType}
                                        selectedValue={boardDetails.MeterType}
                                        selecteCallback={value => this.optionSelectedCall(value, 'MeterType')}
                                        enabledOptions={this.logic.getEnableOptions('MeterType')}
                                    />
                                </div>

                                <div className="col-3">
                                    <RadioObjectGroup
                                        title="27mm Pitch Poles"
                                        options={boardOptions.OptionsChassis1PoleCount27mm}
                                        selectedValue={boardDetails.Chassis1PoleCount27mm}
                                        selecteCallback={value => this.optionSelectedCall(value, 'Chassis1PoleCount27mm')}
                                        enabledOptions={this.logic.getEnableOptions('Chassis1PoleCount27mm')}
                                    />
                                </div>
                            </div>
                        </form>

                        {/* Step 4 */}
                        <form className={'form' + (this.state.currentStep === 4 ? ' form-show' : ' form-hide')}>
                            <div className="row">
                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Chassis 1 Size"
                                        options={boardOptions.OptionsChassis1PoleCount}
                                        selectedValue={boardDetails.Chassis1PoleCount}
                                        selecteCallback={value => this.optionSelectedCall(value, 'Chassis1PoleCount')}
                                        boardClassName="board-chassis-options"
                                        enabledOptions={this.logic.getEnableOptions('Chassis1PoleCount')}
                                    />
                                </div>

                                <div className="col-6">
                                    <RadioObjectGroup
                                        title="Chassis 2 Size"
                                        options={boardOptions.OptionsChassis2PoleCount}
                                        selectedValue={boardDetails.Chassis2PoleCount}
                                        selecteCallback={value => this.optionSelectedCall(value, 'Chassis2PoleCount')}
                                        boardClassName="board-chassis-options"
                                        enabledOptions={this.logic.getEnableOptions('Chassis2PoleCount')}
                                    />
                                </div>
                            </div>
                        </form>

                        {/* Step 5 */}
                        <form className={'form' + (this.state.currentStep === 5 ? ' form-show' : ' form-hide')}>
                            {
                                this.state.board !== null &&
                                <ChassisOptionsForm
                                    options={this.state.chassisOptions}
                                    selected={this.getSelectedChassisOptionArray()}
                                    selecteCallback={this.selectChassisOptionalExtras}
                                    isPole2Filled={this.checkIfIndex2IsFilled()}
                                />
                            }
                        </form>
                    </div>

                    { this.state.availabilityValidationMessages.length > 0 &&
                    <div className="availability-validation-messagges">
                    {
                        this.state.availabilityValidationMessages.map((message, index) => {
                            return (
                                <div className="modal__validation-msg" key={index}>
                                    <span className="alert__icon">
                                        <span className="i-exclamation"></span>
                                    </span>
                                    <span dangerouslySetInnerHTML={{ __html: message }}></span>
                                </div>
                            )
                        })
                    }
                    </div>
                    }

                    {this.renderModalFooter()}
                </div>
            </div>
        )
    }
}


